/** Radios */
input[type='radio']:not(.mdc-radio__native-control) {
  position: absolute;
  display: none;
  visibility: hidden;
  left: -9999px;
  -webkit-appearance: none;
  + label {
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    padding-left: 26px;
    position: relative;
    vertical-align: middle;
    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: -1px;
      width: 20px;
      height: 20px;
      border: 2px solid $baseWhiteBorderWhite;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
    }
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background: $baseAccent;
      position: absolute;
      top: 4px;
      left: 4px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    + input[type='radio'] + label {
      margin-left: 40px;
    }
  }
  &:checked + label:before {
    border: 2px solid $baseAccent;
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  &:disabled {
    + label {
      cursor: not-allowed;
      opacity: 0.6;
      color: $defaultColor;
      &:before,
      &:after {
      }
    }
  }
}
