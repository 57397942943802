@font-face {
  font-family: 'vaylens_icons';
  src:
    url('vaylens_icons.ttf?or6upo') format('truetype'),
    url('vaylens_icons.woff?or6upo') format('woff'),
    url('vaylens_icons.svg?or6upo#vaylens_icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vaylens-icon-"], [class*=" vaylens-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vaylens_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vaylens-icon-data-2-outlined:before {
  content: "\ea81";
}
.vaylens-icon-more-3-filled:before {
  content: "\ee16";
}
.vaylens-icon-charge-port-outlined:before {
  content: "\ee17";
}
.vaylens-icon-ic-charging-station:before {
  content: "\ee14";
}
.vaylens-icon-plugncharge:before {
  content: "\ee13";
}
.vaylens-icon-shield:before {
  content: "\ee12";
}
.vaylens-icon-activity-dashboard-filled:before {
  content: "\e901";
}
.vaylens-icon-activity-dashboard-outlined:before {
  content: "\e906";
}
.vaylens-icon-activity-down-filled:before {
  content: "\e902";
}
.vaylens-icon-activity-down-outlined:before {
  content: "\e903";
}
.vaylens-icon-activity-filled:before {
  content: "\e904";
}
.vaylens-icon-activity-outlined:before {
  content: "\e905";
}
.vaylens-icon-activity-up-filled:before {
  content: "\ee15";
}
.vaylens-icon-activity-up-outlined:before {
  content: "\e907";
}
.vaylens-icon-add-circle-filled:before {
  content: "\e908";
}
.vaylens-icon-add-circle-outlined:before {
  content: "\e909";
}
.vaylens-icon-add-filled:before {
  content: "\e90a";
}
.vaylens-icon-add-item-filled:before {
  content: "\e90b";
}
.vaylens-icon-add-item-outlined:before {
  content: "\e90c";
}
.vaylens-icon-add-outlined:before {
  content: "\e90d";
}
.vaylens-icon-add-sq-filled:before {
  content: "\e90e";
}
.vaylens-icon-add-sq-outlined:before {
  content: "\e90f";
}
.vaylens-icon-ai-assist-filled:before {
  content: "\e910";
}
.vaylens-icon-ai-assist-outlined:before {
  content: "\e911";
}
.vaylens-icon-airdrop-filled:before {
  content: "\e912";
}
.vaylens-icon-airdrop-outlined:before {
  content: "\e913";
}
.vaylens-icon-airplane-filled:before {
  content: "\e914";
}
.vaylens-icon-airplane-outlined:before {
  content: "\e915";
}
.vaylens-icon-airplane-sq-filled:before {
  content: "\e916";
}
.vaylens-icon-airplane-sq-outlined:before {
  content: "\e917";
}
.vaylens-icon-align-center-filled:before {
  content: "\e918";
}
.vaylens-icon-align-center-outlined:before {
  content: "\e919";
}
.vaylens-icon-align-left-filled:before {
  content: "\e91a";
}
.vaylens-icon-align-left-outlined:before {
  content: "\e91b";
}
.vaylens-icon-align-right-filled:before {
  content: "\e91c";
}
.vaylens-icon-align-right-outlined:before {
  content: "\e91d";
}
.vaylens-icon-alignment-bottom-filled:before {
  content: "\e91e";
}
.vaylens-icon-alignment-bottom-outlined:before {
  content: "\e91f";
}
.vaylens-icon-alignment-center-horizontal-filled:before {
  content: "\e920";
}
.vaylens-icon-alignment-center-horizontal-outlined:before {
  content: "\e921";
}
.vaylens-icon-alignment-center-vert-filled:before {
  content: "\e922";
}
.vaylens-icon-alignment-center-vert-outlined:before {
  content: "\e923";
}
.vaylens-icon-alignment-left-filled:before {
  content: "\e924";
}
.vaylens-icon-alignment-left-outlined:before {
  content: "\e925";
}
.vaylens-icon-alignment-right-filled:before {
  content: "\e926";
}
.vaylens-icon-alignment-right-outlined:before {
  content: "\e927";
}
.vaylens-icon-alignment-top-filled:before {
  content: "\e928";
}
.vaylens-icon-alignment-top-outlined:before {
  content: "\e929";
}
.vaylens-icon-anchor-points-filled:before {
  content: "\e92a";
}
.vaylens-icon-anchor-points-outlined:before {
  content: "\e92b";
}
.vaylens-icon-archive-filled:before {
  content: "\e92c";
}
.vaylens-icon-archive-outlined:before {
  content: "\e92d";
}
.vaylens-icon-archive-outlined-2:before {
  content: "\e92e";
}
.vaylens-icon-archive-outlined-3:before {
  content: "\e92f";
}
.vaylens-icon-arrow-down-circle-filled:before {
  content: "\e930";
}
.vaylens-icon-arrow-down-circle-outlined:before {
  content: "\e931";
}
.vaylens-icon-arrow-down-filled:before {
  content: "\e932";
}
.vaylens-icon-arrow-down-outlined:before {
  content: "\e933";
}
.vaylens-icon-arrow-left-circle-filled:before {
  content: "\e934";
}
.vaylens-icon-arrow-left-circle-outlined:before {
  content: "\e935";
}
.vaylens-icon-arrow-left-filled:before {
  content: "\e936";
}
.vaylens-icon-arrow-left-outlined:before {
  content: "\e937";
}
.vaylens-icon-arrow-recieve-filled:before {
  content: "\e938";
}
.vaylens-icon-arrow-recieve-outlined:before {
  content: "\e939";
}
.vaylens-icon-arrow-send-outlined:before {
  content: "\e93f";
}
.vaylens-icon-arrow-right-send-outlined:before {
  content: "\e900";
}
.vaylens-icon-arrow-right-circle-filled:before {
  content: "\e93a";
}
.vaylens-icon-arrow-right-circle-outlined:before {
  content: "\e93b";
}
.vaylens-icon-arrow-right-filled:before {
  content: "\e93c";
}
.vaylens-icon-arrow-right-outlined:before {
  content: "\e93d";
}
.vaylens-icon-arrow-send-filled:before {
  content: "\e93e";
}
.vaylens-icon-arrow-up-circle-filled:before {
  content: "\e940";
}
.vaylens-icon-arrow-up-circle-outlined:before {
  content: "\e941";
}
.vaylens-icon-arrow-up-filled:before {
  content: "\e942";
}
.vaylens-icon-arrow-up-outlined:before {
  content: "\e943";
}
.vaylens-icon-audio-track-filled:before {
  content: "\e944";
}
.vaylens-icon-audio-track-outlined:before {
  content: "\e945";
}
.vaylens-icon-audio-track-sq-filled:before {
  content: "\e946";
}
.vaylens-icon-audio-track-sq-outlined:before {
  content: "\e947";
}
.vaylens-icon-auto-brightness-filled:before {
  content: "\e948";
}
.vaylens-icon-auto-brightness-outlined:before {
  content: "\e949";
}
.vaylens-icon-award-filled:before {
  content: "\e94a";
}
.vaylens-icon-award-outlined:before {
  content: "\e94b";
}
.vaylens-icon-backward-10s-filled:before {
  content: "\e94c";
}
.vaylens-icon-backward-10s-outlined:before {
  content: "\e94d";
}
.vaylens-icon-backward-15s-filled:before {
  content: "\e94e";
}
.vaylens-icon-backward-15s-outlined:before {
  content: "\e94f";
}
.vaylens-icon-backward-5s-filled:before {
  content: "\e950";
}
.vaylens-icon-backward-5s-outlined:before {
  content: "\e951";
}
.vaylens-icon-backward-filled:before {
  content: "\e952";
}
.vaylens-icon-backward-outlined:before {
  content: "\e953";
}
.vaylens-icon-bag-1-filled:before {
  content: "\e954";
}
.vaylens-icon-bag-1-outlined:before {
  content: "\e955";
}
.vaylens-icon-bag-2-filled:before {
  content: "\e956";
}
.vaylens-icon-bag-2-outlined:before {
  content: "\e957";
}
.vaylens-icon-bag-3-filled:before {
  content: "\e958";
}
.vaylens-icon-bag-3-outlined:before {
  content: "\e959";
}
.vaylens-icon-bag-4-filled:before {
  content: "\e95a";
}
.vaylens-icon-bag-4-outlined:before {
  content: "\e95b";
}
.vaylens-icon-barcode-filled:before {
  content: "\e95c";
}
.vaylens-icon-barcode-outlined:before {
  content: "\e95d";
}
.vaylens-icon-battery-30-filled:before {
  content: "\e95e";
}
.vaylens-icon-battery-30-outlined:before {
  content: "\e95f";
}
.vaylens-icon-battery-60-filled:before {
  content: "\e960";
}
.vaylens-icon-battery-60-outlined:before {
  content: "\e961";
}
.vaylens-icon-battery-charging-filled:before {
  content: "\e962";
}
.vaylens-icon-battery-charging-outlined:before {
  content: "\e963";
}
.vaylens-icon-battery-empty-filled:before {
  content: "\e964";
}
.vaylens-icon-battery-empty-outlined:before {
  content: "\e965";
}
.vaylens-icon-battery-full-filled:before {
  content: "\e966";
}
.vaylens-icon-battery-full-outlined:before {
  content: "\e967";
}
.vaylens-icon-bill-filled:before {
  content: "\e968";
}
.vaylens-icon-bill-outlined:before {
  content: "\e969";
}
.vaylens-icon-blend-filled:before {
  content: "\e96a";
}
.vaylens-icon-blend-outlined:before {
  content: "\e96b";
}
.vaylens-icon-blutooth-circle-filled:before {
  content: "\e96c";
}
.vaylens-icon-blutooth-circle-outlined:before {
  content: "\e96d";
}
.vaylens-icon-blutooth-sq-filled:before {
  content: "\e96e";
}
.vaylens-icon-blutooth-sq-outlined:before {
  content: "\e96f";
}
.vaylens-icon-book-1-filled:before {
  content: "\e970";
}
.vaylens-icon-book-1-outlined:before {
  content: "\e971";
}
.vaylens-icon-book-2-filled:before {
  content: "\e972";
}
.vaylens-icon-book-2-outlined:before {
  content: "\e973";
}
.vaylens-icon-bookmark-filled:before {
  content: "\e974";
}
.vaylens-icon-bookmark-outlined:before {
  content: "\e975";
}
.vaylens-icon-box-add-filled:before {
  content: "\e976";
}
.vaylens-icon-box-add-outlined:before {
  content: "\e977";
}
.vaylens-icon-box-check-filled:before {
  content: "\e978";
}
.vaylens-icon-box-check-outlined:before {
  content: "\e979";
}
.vaylens-icon-box-convert-filled:before {
  content: "\e97a";
}
.vaylens-icon-box-convert-outlined:before {
  content: "\e97b";
}
.vaylens-icon-box-filled:before {
  content: "\e97c";
}
.vaylens-icon-box-outlined:before {
  content: "\e97d";
}
.vaylens-icon-box-processing-filled:before {
  content: "\e97e";
}
.vaylens-icon-box-processing-outlined:before {
  content: "\e97f";
}
.vaylens-icon-box-remove-filled:before {
  content: "\e980";
}
.vaylens-icon-box-remove-outlined:before {
  content: "\e981";
}
.vaylens-icon-box-rotate-filled:before {
  content: "\e982";
}
.vaylens-icon-box-rotate-outlined:before {
  content: "\e983";
}
.vaylens-icon-box-scan-filled:before {
  content: "\e984";
}
.vaylens-icon-box-scan-outlined:before {
  content: "\e985";
}
.vaylens-icon-box-search-filled:before {
  content: "\e986";
}
.vaylens-icon-box-search-outlined:before {
  content: "\e987";
}
.vaylens-icon-box-sq-filled:before {
  content: "\e988";
}
.vaylens-icon-box-sq-outlined:before {
  content: "\e989";
}
.vaylens-icon-briefcase-filled:before {
  content: "\e98a";
}
.vaylens-icon-briefcase-outlined:before {
  content: "\e98b";
}
.vaylens-icon-bring-to-front-filled:before {
  content: "\e98c";
}
.vaylens-icon-bring-to-front-outlined:before {
  content: "\e98d";
}
.vaylens-icon-brush-1-filled:before {
  content: "\e98e";
}
.vaylens-icon-brush-1-outlined:before {
  content: "\e98f";
}
.vaylens-icon-brush-2-filled:before {
  content: "\e990";
}
.vaylens-icon-brush-2-outlined:before {
  content: "\e991";
}
.vaylens-icon-brush-3-filled:before {
  content: "\e992";
}
.vaylens-icon-brush-3-outlined:before {
  content: "\e993";
}
.vaylens-icon-building-1-filled:before {
  content: "\e994";
}
.vaylens-icon-building-1-outlined:before {
  content: "\e995";
}
.vaylens-icon-building-10-filled:before {
  content: "\e996";
}
.vaylens-icon-building-10-outlined:before {
  content: "\e997";
}
.vaylens-icon-building-2-filled:before {
  content: "\e998";
}
.vaylens-icon-building-2-outlined:before {
  content: "\e999";
}
.vaylens-icon-building-3-filled:before {
  content: "\e99a";
}
.vaylens-icon-building-3-outlined:before {
  content: "\e99b";
}
.vaylens-icon-building-4-filled:before {
  content: "\e99c";
}
.vaylens-icon-building-4-outlined:before {
  content: "\e99d";
}
.vaylens-icon-building-5-filled:before {
  content: "\e99e";
}
.vaylens-icon-building-5-outlined:before {
  content: "\e99f";
}
.vaylens-icon-building-6-filled:before {
  content: "\e9a0";
}
.vaylens-icon-building-6-outlined:before {
  content: "\e9a1";
}
.vaylens-icon-building-7-filled:before {
  content: "\e9a2";
}
.vaylens-icon-building-7-outlined:before {
  content: "\e9a3";
}
.vaylens-icon-building-8-filled:before {
  content: "\e9a4";
}
.vaylens-icon-building-8-outlined:before {
  content: "\e9a5";
}
.vaylens-icon-building-9-filled:before {
  content: "\e9a6";
}
.vaylens-icon-building-9-outlined:before {
  content: "\e9a7";
}
.vaylens-icon-bus-filled:before {
  content: "\e9a8";
}
.vaylens-icon-bus-outlined:before {
  content: "\e9a9";
}
.vaylens-icon-calculate-filled:before {
  content: "\e9aa";
}
.vaylens-icon-calculate-outlined:before {
  content: "\e9ab";
}
.vaylens-icon-calculator-filled:before {
  content: "\e9ac";
}
.vaylens-icon-calculator-outlined:before {
  content: "\e9ad";
}
.vaylens-icon-calcultions-filled:before {
  content: "\e9ae";
}
.vaylens-icon-calcultions-outlined:before {
  content: "\e9af";
}
.vaylens-icon-calendar-1-filled:before {
  content: "\e9b0";
}
.vaylens-icon-calendar-1-outlined:before {
  content: "\e9b1";
}
.vaylens-icon-calendar-2-edit-filled:before {
  content: "\e9b2";
}
.vaylens-icon-calendar-2-edit-outlined:before {
  content: "\e9b3";
}
.vaylens-icon-calendar-2-filled:before {
  content: "\e9b4";
}
.vaylens-icon-calendar-2-outlined:before {
  content: "\e9b5";
}
.vaylens-icon-calendar-circle-filled:before {
  content: "\e9b6";
}
.vaylens-icon-calendar-circle-outlined:before {
  content: "\e9b7";
}
.vaylens-icon-camera-filled:before {
  content: "\e9b8";
}
.vaylens-icon-camera-outlined:before {
  content: "\e9b9";
}
.vaylens-icon-captions-filled:before {
  content: "\e9ba";
}
.vaylens-icon-captions-outlined:before {
  content: "\e9bb";
}
.vaylens-icon-car-driving-filled:before {
  content: "\e9bc";
}
.vaylens-icon-car-driving-outlined:before {
  content: "\e9bd";
}
.vaylens-icon-car-filled:before {
  content: "\e9be";
}
.vaylens-icon-car-outlined:before {
  content: "\e9bf";
}
.vaylens-icon-car-smart-filled:before {
  content: "\e9c0";
}
.vaylens-icon-car-smart-outlined:before {
  content: "\e9c1";
}
.vaylens-icon-card-2-add-filled:before {
  content: "\e9c2";
}
.vaylens-icon-card-2-add-outlined:before {
  content: "\e9c3";
}
.vaylens-icon-card-2-check-filled:before {
  content: "\e9c4";
}
.vaylens-icon-card-2-check-outlined:before {
  content: "\e9c5";
}
.vaylens-icon-card-2-edit-filled:before {
  content: "\e9c6";
}
.vaylens-icon-card-2-edit-outlined:before {
  content: "\e9c7";
}
.vaylens-icon-card-2-filled:before {
  content: "\e9c8";
}
.vaylens-icon-card-2-outlined:before {
  content: "\e9c9";
}
.vaylens-icon-card-2-recieve-filled:before {
  content: "\e9ca";
}
.vaylens-icon-card-2-recieve-outlined:before {
  content: "\e9cb";
}
.vaylens-icon-card-2-remove-filled:before {
  content: "\e9cc";
}
.vaylens-icon-card-2-remove-outlined:before {
  content: "\e9cd";
}
.vaylens-icon-card-2-send-filled:before {
  content: "\e9ce";
}
.vaylens-icon-card-2-send-outlined:before {
  content: "\e9cf";
}
.vaylens-icon-card-check-filled:before {
  content: "\e9d0";
}
.vaylens-icon-card-check-outlined:before {
  content: "\e9d1";
}
.vaylens-icon-card-convert-filled:before {
  content: "\e9d2";
}
.vaylens-icon-card-convert-outlined:before {
  content: "\e9d3";
}
.vaylens-icon-card-remove-filled:before {
  content: "\e9d4";
}
.vaylens-icon-card-remove-outlined:before {
  content: "\e9d5";
}
.vaylens-icon-card-security-filled:before {
  content: "\e9d6";
}
.vaylens-icon-card-security-outlined:before {
  content: "\e9d7";
}
.vaylens-icon-cards-filled:before {
  content: "\e9d8";
}
.vaylens-icon-cards-outlined:before {
  content: "\e9d9";
}
.vaylens-icon-category-1-filled:before {
  content: "\e9da";
}
.vaylens-icon-category-1-outlined:before {
  content: "\e9db";
}
.vaylens-icon-category-2-filled:before {
  content: "\e9dc";
}
.vaylens-icon-category-2-outlined:before {
  content: "\e9dd";
}
.vaylens-icon-celebration-filled:before {
  content: "\e9de";
}
.vaylens-icon-celebration-outlined:before {
  content: "\e9df";
}
.vaylens-icon-chargport-ccs:before {
  content: "\e9e0";
}
.vaylens-icon-chargport-chademo:before {
  content: "\e9e1";
}
.vaylens-icon-chargport-gb-t:before {
  content: "\e9e2";
}
.vaylens-icon-chargport-type4:before {
  content: "\e9e3";
}
.vaylens-icon-chargport-type5:before {
  content: "\e9e4";
}
.vaylens-icon-chargport-type6:before {
  content: "\e9e5";
}
.vaylens-icon-chart-1-filled:before {
  content: "\e9e6";
}
.vaylens-icon-chart-1-outlined:before {
  content: "\e9e7";
}
.vaylens-icon-chart-2-filled:before {
  content: "\e9e8";
}
.vaylens-icon-chart-2-outlined:before {
  content: "\e9e9";
}
.vaylens-icon-chart-3-filled:before {
  content: "\e9ea";
}
.vaylens-icon-chart-3-outlined:before {
  content: "\e9eb";
}
.vaylens-icon-chart-4-filled:before {
  content: "\e9ec";
}
.vaylens-icon-chart-4-outlined:before {
  content: "\e9ed";
}
.vaylens-icon-chart-failure-filled:before {
  content: "\e9ee";
}
.vaylens-icon-chart-failure-outlined:before {
  content: "\e9ef";
}
.vaylens-icon-chart-filled:before {
  content: "\e9f0";
}
.vaylens-icon-chart-outlined:before {
  content: "\e9f1";
}
.vaylens-icon-chart-presentation-filled:before {
  content: "\e9f2";
}
.vaylens-icon-chart-presentation-outlined:before {
  content: "\e9f3";
}
.vaylens-icon-chart-sq-filled:before {
  content: "\e9f4";
}
.vaylens-icon-chart-sq-outlined:before {
  content: "\e9f5";
}
.vaylens-icon-chart-success-filled:before {
  content: "\e9f6";
}
.vaylens-icon-chart-success-outlined:before {
  content: "\e9f7";
}
.vaylens-icon-chat-1-filled:before {
  content: "\e9f8";
}
.vaylens-icon-chat-1-outlined:before {
  content: "\e9f9";
}
.vaylens-icon-chat-2-filled:before {
  content: "\e9fa";
}
.vaylens-icon-chat-2-outlined:before {
  content: "\e9fb";
}
.vaylens-icon-chat-3-filled:before {
  content: "\e9fc";
}
.vaylens-icon-chat-3-outlined:before {
  content: "\e9fd";
}
.vaylens-icon-chat-4-filled:before {
  content: "\e9fe";
}
.vaylens-icon-chat-4-outlined:before {
  content: "\e9ff";
}
.vaylens-icon-chat-5-filled:before {
  content: "\ea00";
}
.vaylens-icon-chat-5-outlined:before {
  content: "\ea01";
}
.vaylens-icon-check-circle-filled:before {
  content: "\ea02";
}
.vaylens-icon-check-circle-outlined:before {
  content: "\ea03";
}
.vaylens-icon-check-filled:before {
  content: "\ea04";
}
.vaylens-icon-check-outlined:before {
  content: "\ea05";
}
.vaylens-icon-check-sq-filled:before {
  content: "\ea06";
}
.vaylens-icon-check-sq-outlined:before {
  content: "\ea07";
}
.vaylens-icon-chevron-down-circle-filled:before {
  content: "\ea08";
}
.vaylens-icon-chevron-down-circle-outlined:before {
  content: "\ea09";
}
.vaylens-icon-chevron-down-filled:before {
  content: "\ea0a";
}
.vaylens-icon-chevron-down-outlined:before {
  content: "\ea0b";
}
.vaylens-icon-chevron-down-sq-filled:before {
  content: "\ea0c";
}
.vaylens-icon-chevron-down-sq-outlined:before {
  content: "\ea0d";
}
.vaylens-icon-chevron-left-circle-filled:before {
  content: "\ea0e";
}
.vaylens-icon-chevron-left-circle-outlined:before {
  content: "\ea0f";
}
.vaylens-icon-chevron-left-filled:before {
  content: "\ea10";
}
.vaylens-icon-chevron-left-outlined:before {
  content: "\ea11";
}
.vaylens-icon-chevron-left-sq-filled:before {
  content: "\ea12";
}
.vaylens-icon-chevron-left-sq-outlined:before {
  content: "\ea13";
}
.vaylens-icon-chevron-right-circle-filled:before {
  content: "\ea14";
}
.vaylens-icon-chevron-right-circle-outlined:before {
  content: "\ea15";
}
.vaylens-icon-chevron-right-filled:before {
  content: "\ea16";
}
.vaylens-icon-chevron-right-outlined:before {
  content: "\ea17";
}
.vaylens-icon-chevron-right-sq-filled:before {
  content: "\ea18";
}
.vaylens-icon-chevron-right-sq-outlined:before {
  content: "\ea19";
}
.vaylens-icon-chevron-up-circle-filled:before {
  content: "\ea1a";
}
.vaylens-icon-chevron-up-circle-outlined:before {
  content: "\ea1b";
}
.vaylens-icon-chevron-up-filled:before {
  content: "\ea1c";
}
.vaylens-icon-chevron-up-outlined:before {
  content: "\ea1d";
}
.vaylens-icon-chevron-up-sq-filled:before {
  content: "\ea1e";
}
.vaylens-icon-chevron-up-sq-outlined:before {
  content: "\ea1f";
}
.vaylens-icon-clipboard-cancel-filled:before {
  content: "\ea20";
}
.vaylens-icon-clipboard-cancel-outlined:before {
  content: "\ea21";
}
.vaylens-icon-clipboard-check-filled:before {
  content: "\ea22";
}
.vaylens-icon-clipboard-check-outlined:before {
  content: "\ea23";
}
.vaylens-icon-clipboard-in-filled:before {
  content: "\ea24";
}
.vaylens-icon-clipboard-in-outlined:before {
  content: "\ea25";
}
.vaylens-icon-clipboard-out-filled:before {
  content: "\ea26";
}
.vaylens-icon-clipboard-out-outlined:before {
  content: "\ea27";
}
.vaylens-icon-clipboard-text-filled:before {
  content: "\ea28";
}
.vaylens-icon-clipboard-text-outlined:before {
  content: "\ea29";
}
.vaylens-icon-clock-filled:before {
  content: "\ea2a";
}
.vaylens-icon-clock-outlined:before {
  content: "\ea2b";
}
.vaylens-icon-close-circle-filled:before {
  content: "\ea2c";
}
.vaylens-icon-close-circle-outlined:before {
  content: "\ea2d";
}
.vaylens-icon-close-filled:before {
  content: "\ea2e";
}
.vaylens-icon-close-outlined:before {
  content: "\ea2f";
}
.vaylens-icon-close-sq-filled:before {
  content: "\ea30";
}
.vaylens-icon-close-sq-outlined:before {
  content: "\ea31";
}
.vaylens-icon-cloud-connected-filled:before {
  content: "\ea32";
}
.vaylens-icon-cloud-connected-outlined:before {
  content: "\ea33";
}
.vaylens-icon-cloud-filled:before {
  content: "\ea34";
}
.vaylens-icon-cloud-network-filled:before {
  content: "\ea35";
}
.vaylens-icon-cloud-network-outlined:before {
  content: "\ea36";
}
.vaylens-icon-cloud-outlined:before {
  content: "\ea37";
}
.vaylens-icon-code-1-filled:before {
  content: "\ea38";
}
.vaylens-icon-code-1-outlined:before {
  content: "\ea39";
}
.vaylens-icon-code-2-filled:before {
  content: "\ea3a";
}
.vaylens-icon-code-2-outlined:before {
  content: "\ea3b";
}
.vaylens-icon-code-3-filled:before {
  content: "\ea3c";
}
.vaylens-icon-code-3-outlined:before {
  content: "\ea3d";
}
.vaylens-icon-code-4-filled:before {
  content: "\ea3e";
}
.vaylens-icon-code-4-outlined:before {
  content: "\ea3f";
}
.vaylens-icon-code-document-filled:before {
  content: "\ea40";
}
.vaylens-icon-code-document-outlined:before {
  content: "\ea41";
}
.vaylens-icon-coffee-filled:before {
  content: "\ea42";
}
.vaylens-icon-coffee-outlined:before {
  content: "\ea43";
}
.vaylens-icon-coin-filled:before {
  content: "\ea44";
}
.vaylens-icon-coin-outlined:before {
  content: "\ea45";
}
.vaylens-icon-coins-filled:before {
  content: "\ea46";
}
.vaylens-icon-coins-outlined:before {
  content: "\ea47";
}
.vaylens-icon-command-filled:before {
  content: "\ea48";
}
.vaylens-icon-command-outlined:before {
  content: "\ea49";
}
.vaylens-icon-command-sq-filled:before {
  content: "\ea4a";
}
.vaylens-icon-command-sq-outlined:before {
  content: "\ea4b";
}
.vaylens-icon-component-filled:before {
  content: "\ea4c";
}
.vaylens-icon-component-main-filled:before {
  content: "\ea4d";
}
.vaylens-icon-component-main-outlined:before {
  content: "\ea4e";
}
.vaylens-icon-component-outlined:before {
  content: "\ea4f";
}
.vaylens-icon-convert-circle-filled:before {
  content: "\ea50";
}
.vaylens-icon-convert-circle-outlined:before {
  content: "\ea51";
}
.vaylens-icon-convert-hor-filled:before {
  content: "\ea52";
}
.vaylens-icon-convert-hor-outlined:before {
  content: "\ea53";
}
.vaylens-icon-convert-hor-sq-filled:before {
  content: "\ea54";
}
.vaylens-icon-convert-hor-sq-outlined:before {
  content: "\ea55";
}
.vaylens-icon-convert-vert-filled:before {
  content: "\ea56";
}
.vaylens-icon-convert-vert-outlined:before {
  content: "\ea57";
}
.vaylens-icon-convert-vert-sq-filled:before {
  content: "\ea58";
}
.vaylens-icon-convert-vert-sq-outlined:before {
  content: "\ea59";
}
.vaylens-icon-copy-filled:before {
  content: "\ea5a";
}
.vaylens-icon-copy-filled-2:before {
  content: "\ea5b";
}
.vaylens-icon-copy-outlined:before {
  content: "\ea5c";
}
.vaylens-icon-copy-outlined-2:before {
  content: "\ea5d";
}
.vaylens-icon-copy-success-filled:before {
  content: "\ea5e";
}
.vaylens-icon-copy-success-outlined:before {
  content: "\ea5f";
}
.vaylens-icon-copyright-creative-filled:before {
  content: "\ea60";
}
.vaylens-icon-copyright-creative-outlined:before {
  content: "\ea61";
}
.vaylens-icon-copyright-filled:before {
  content: "\ea62";
}
.vaylens-icon-copyright-outlined:before {
  content: "\ea63";
}
.vaylens-icon-cpu-1-filled:before {
  content: "\ea64";
}
.vaylens-icon-cpu-1-outlined:before {
  content: "\ea65";
}
.vaylens-icon-cpu-2-filled:before {
  content: "\ea66";
}
.vaylens-icon-cpu-2-outlined:before {
  content: "\ea67";
}
.vaylens-icon-cpu-settings-filled:before {
  content: "\ea68";
}
.vaylens-icon-cpu-settings-outlined:before {
  content: "\ea69";
}
.vaylens-icon-crop-filled:before {
  content: "\ea6a";
}
.vaylens-icon-crop-outlined:before {
  content: "\ea6b";
}
.vaylens-icon-crown-2-filled:before {
  content: "\ea6c";
}
.vaylens-icon-crown-2-outlined:before {
  content: "\ea6d";
}
.vaylens-icon-crown-filled:before {
  content: "\ea6e";
}
.vaylens-icon-crown-outlined:before {
  content: "\ea6f";
}
.vaylens-icon-cube-filled:before {
  content: "\ea70";
}
.vaylens-icon-cube-outlined:before {
  content: "\ea71";
}
.vaylens-icon-cup-filled:before {
  content: "\ea72";
}
.vaylens-icon-cup-outlined:before {
  content: "\ea73";
}
.vaylens-icon-dashboard-1-filled:before {
  content: "\ea74";
}
.vaylens-icon-dashboard-1-outlined:before {
  content: "\ea75";
}
.vaylens-icon-dashboard-2-filled:before {
  content: "\ea76";
}
.vaylens-icon-dashboard-2-outlined:before {
  content: "\ea77";
}
.vaylens-icon-dashboard-3-filled:before {
  content: "\ea78";
}
.vaylens-icon-dashboard-3-outlined:before {
  content: "\ea79";
}
.vaylens-icon-dashboard-4-filled:before {
  content: "\ea7a";
}
.vaylens-icon-dashboard-4-outlined:before {
  content: "\ea7b";
}
.vaylens-icon-dashboard-add-filled:before {
  content: "\ea7c";
}
.vaylens-icon-dashboard-add-outlined:before {
  content: "\ea7d";
}
.vaylens-icon-data-1-filled:before {
  content: "\ea7e";
}
.vaylens-icon-data-1-outlined:before {
  content: "\ea7f";
}
.vaylens-icon-data-2-filled:before {
  content: "\ea80";
}
.vaylens-icon-design-tools-filled:before {
  content: "\ea82";
}
.vaylens-icon-design-tools-outlined:before {
  content: "\ea83";
}
.vaylens-icon-devices-1-filled:before {
  content: "\ea84";
}
.vaylens-icon-devices-1-outlined:before {
  content: "\ea85";
}
.vaylens-icon-devices-2-filled:before {
  content: "\ea86";
}
.vaylens-icon-devices-2-outlined:before {
  content: "\ea87";
}
.vaylens-icon-diagram-fav-filled:before {
  content: "\ea88";
}
.vaylens-icon-diagram-fav-outlined:before {
  content: "\ea89";
}
.vaylens-icon-diagram-filled:before {
  content: "\ea8a";
}
.vaylens-icon-diagram-outlined:before {
  content: "\ea8b";
}
.vaylens-icon-diamond-filled:before {
  content: "\ea8c";
}
.vaylens-icon-diamond-outlined:before {
  content: "\ea8d";
}
.vaylens-icon-dircetion-filled:before {
  content: "\ea8e";
}
.vaylens-icon-dircetion-outlined:before {
  content: "\ea8f";
}
.vaylens-icon-discount-filled:before {
  content: "\ea90";
}
.vaylens-icon-discount-outlined:before {
  content: "\ea91";
}
.vaylens-icon-discover-filled:before {
  content: "\ea92";
}
.vaylens-icon-discover-filled-2:before {
  content: "\ea93";
}
.vaylens-icon-discover-outlined:before {
  content: "\ea94";
}
.vaylens-icon-discover-outlined-2:before {
  content: "\ea95";
}
.vaylens-icon-dislike-filled:before {
  content: "\ea96";
}
.vaylens-icon-dislike-outlined:before {
  content: "\ea97";
}
.vaylens-icon-document-backward-filled:before {
  content: "\ea98";
}
.vaylens-icon-document-backward-outlined:before {
  content: "\ea99";
}
.vaylens-icon-document-cloud-filled:before {
  content: "\ea9a";
}
.vaylens-icon-document-cloud-outlined:before {
  content: "\ea9b";
}
.vaylens-icon-document-download-filled:before {
  content: "\ea9c";
}
.vaylens-icon-document-download-outlined:before {
  content: "\ea9d";
}
.vaylens-icon-document-favorite-filled:before {
  content: "\ea9e";
}
.vaylens-icon-document-favorite-outlined:before {
  content: "\ea9f";
}
.vaylens-icon-document-filled:before {
  content: "\eaa0";
}
.vaylens-icon-document-filled-2:before {
  content: "\eaa1";
}
.vaylens-icon-document-forward-filled:before {
  content: "\eaa2";
}
.vaylens-icon-document-forward-outlined:before {
  content: "\eaa3";
}
.vaylens-icon-document-outlined:before {
  content: "\eaa4";
}
.vaylens-icon-document-text-filled:before {
  content: "\eaa5";
}
.vaylens-icon-document-text-outlined:before {
  content: "\eaa6";
}
.vaylens-icon-document-upload-filled:before {
  content: "\eaa7";
}
.vaylens-icon-download-1-sq-filled:before {
  content: "\eaa8";
}
.vaylens-icon-download-1-sq-outlined:before {
  content: "\eaa9";
}
.vaylens-icon-download-2-filled:before {
  content: "\eaaa";
}
.vaylens-icon-download-2-outlined:before {
  content: "\eaab";
}
.vaylens-icon-download-3-filled:before {
  content: "\eaac";
}
.vaylens-icon-download-3-outlined:before {
  content: "\eaad";
}
.vaylens-icon-dragging-filled:before {
  content: "\eaae";
}
.vaylens-icon-dragging-outlined:before {
  content: "\eaaf";
}
.vaylens-icon-drive-filled:before {
  content: "\eab0";
}
.vaylens-icon-drive-outlined:before {
  content: "\eab1";
}
.vaylens-icon-driver-filled:before {
  content: "\eab2";
}
.vaylens-icon-driver-outlined:before {
  content: "\eab3";
}
.vaylens-icon-drop-filled:before {
  content: "\eab4";
}
.vaylens-icon-drop-outlined:before {
  content: "\eab5";
}
.vaylens-icon-edit-1-filled:before {
  content: "\eab6";
}
.vaylens-icon-edit-1-outlined:before {
  content: "\eab7";
}
.vaylens-icon-edit-2-filled:before {
  content: "\eab8";
}
.vaylens-icon-edit-2-outlined:before {
  content: "\eab9";
}
.vaylens-icon-electricity-filled:before {
  content: "\eaba";
}
.vaylens-icon-electricity-outlined:before {
  content: "\eabb";
}
.vaylens-icon-elevation-filled:before {
  content: "\eabc";
}
.vaylens-icon-elevation-outlined:before {
  content: "\eabd";
}
.vaylens-icon-email-filled:before {
  content: "\eabe";
}
.vaylens-icon-email-outlined:before {
  content: "\eabf";
}
.vaylens-icon-eoperate-ic-access-control:before {
  content: "\eac0";
}
.vaylens-icon-eoperate-ic-activity-overview:before {
  content: "\eac1";
}
.vaylens-icon-eoperate-ic-assign-ev-tariffs:before {
  content: "\eac2";
}
.vaylens-icon-eoperate-ic-assignment:before {
  content: "\eac3";
}
.vaylens-icon-eoperate-ic-bdr-management:before {
  content: "\eac4";
}
.vaylens-icon-eoperate-ic-billings:before {
  content: "\eac5";
}
.vaylens-icon-eoperate-ic-business-partner:before {
  content: "\eac6";
}
.vaylens-icon-eoperate-ic-business-partner-management:before {
  content: "\eac7";
}
.vaylens-icon-eoperate-ic-charging-infrastructure:before {
  content: "\eac8";
}
.vaylens-icon-eoperate-ic-charging-infrastructure-notifications:before {
  content: "\eac9";
}
.vaylens-icon-eoperate-ic-charging-records:before {
  content: "\eaca";
}
.vaylens-icon-eoperate-ic-clearing-house:before {
  content: "\eacb";
}
.vaylens-icon-eoperate-ic-client-account-management:before {
  content: "\eacc";
}
.vaylens-icon-eoperate-ic-close:before {
  content: "\eacd";
}
.vaylens-icon-eoperate-ic-co2:before {
  content: "\eace";
}
.vaylens-icon-eoperate-ic-configuration:before {
  content: "\eacf";
}
.vaylens-icon-eoperate-ic-dashboard:before {
  content: "\ead0";
}
.vaylens-icon-eoperate-ic-direct-payment:before {
  content: "\ead1";
}
.vaylens-icon-eoperate-ic-emaid-rfid-management:before {
  content: "\ead2";
}
.vaylens-icon-eoperate-ic-ev-driver:before {
  content: "\ead3";
}
.vaylens-icon-eoperate-ic-faq:before {
  content: "\ead4";
}
.vaylens-icon-eoperate-ic-filter:before {
  content: "\ead5";
}
.vaylens-icon-eoperate-ic-firmware:before {
  content: "\ead6";
}
.vaylens-icon-eoperate-ic-graph:before {
  content: "\ead7";
}
.vaylens-icon-eoperate-ic-help:before {
  content: "\ead8";
}
.vaylens-icon-eoperate-ic-increase-revenue:before {
  content: "\ead9";
}
.vaylens-icon-eoperate-ic-info:before {
  content: "\eada";
}
.vaylens-icon-eoperate-ic-layers:before {
  content: "\eadb";
}
.vaylens-icon-eoperate-ic-legal:before {
  content: "\eadc";
}
.vaylens-icon-eoperate-ic-list:before {
  content: "\eadd";
}
.vaylens-icon-eoperate-ic-load:before {
  content: "\eade";
}
.vaylens-icon-eoperate-ic-load-management:before {
  content: "\eadf";
}
.vaylens-icon-eoperate-ic-location-management:before {
  content: "\eae0";
}
.vaylens-icon-eoperate-ic-login-messages:before {
  content: "\eae1";
}
.vaylens-icon-eoperate-ic-logout:before {
  content: "\eae2";
}
.vaylens-icon-eoperate-ic-manage:before {
  content: "\eae3";
}
.vaylens-icon-eoperate-ic-manage-chl-tariffs:before {
  content: "\eae4";
}
.vaylens-icon-eoperate-ic-manage-cpo-tariffs:before {
  content: "\eae5";
}
.vaylens-icon-eoperate-ic-manage-cpo-tariffs-1:before {
  content: "\eae6";
}
.vaylens-icon-eoperate-ic-manage-ev-tariffs:before {
  content: "\eae7";
}
.vaylens-icon-eoperate-ic-manage-roaming-offers:before {
  content: "\eae8";
}
.vaylens-icon-eoperate-ic-manual:before {
  content: "\eae9";
}
.vaylens-icon-eoperate-ic-map:before {
  content: "\eaea";
}
.vaylens-icon-eoperate-ic-monitor:before {
  content: "\eaeb";
}
.vaylens-icon-eoperate-ic-notifications:before {
  content: "\eaec";
}
.vaylens-icon-eoperate-ic-opening-hours:before {
  content: "\eaed";
}
.vaylens-icon-eoperate-ic-payment-terminal:before {
  content: "\eaee";
}
.vaylens-icon-eoperate-ic-payments:before {
  content: "\eaef";
}
.vaylens-icon-eoperate-ic-portal:before {
  content: "\eaf0";
}
.vaylens-icon-eoperate-ic-portal-user:before {
  content: "\eaf1";
}
.vaylens-icon-eoperate-ic-profiles:before {
  content: "\eaf2";
}
.vaylens-icon-eoperate-ic-roaming-relations:before {
  content: "\eaf3";
}
.vaylens-icon-eoperate-ic-search:before {
  content: "\eaf4";
}
.vaylens-icon-eoperate-ic-settings:before {
  content: "\eaf5";
}
.vaylens-icon-eoperate-ic-station-management:before {
  content: "\eaf6";
}
.vaylens-icon-eoperate-ic-support:before {
  content: "\eaf7";
}
.vaylens-icon-eoperate-ic-terminal-management:before {
  content: "\eaf8";
}
.vaylens-icon-eoperate-ic-uc-pricing:before {
  content: "\eaf9";
}
.vaylens-icon-eoperate-ic-user-charging:before {
  content: "\eafa";
}
.vaylens-icon-eoperate-ic-user-profile:before {
  content: "\eafb";
}
.vaylens-icon-eoperate-ic-voucher-management:before {
  content: "\eafc";
}
.vaylens-icon-eoperate-ic-webservice-feed-endpoints:before {
  content: "\eafd";
}
.vaylens-icon-eoperate-logo-small:before {
  content: "\eafe";
}
.vaylens-icon-export-circle-filled:before {
  content: "\eaff";
}
.vaylens-icon-export-circle-outlined:before {
  content: "\eb00";
}
.vaylens-icon-export-sq-filled:before {
  content: "\eb01";
}
.vaylens-icon-export-sq-outlined:before {
  content: "\eb02";
}
.vaylens-icon-fatrows-filled:before {
  content: "\eb03";
}
.vaylens-icon-fatrows-outlined:before {
  content: "\eb04";
}
.vaylens-icon-filled:before {
  content: "\eb05";
}
.vaylens-icon-filter-add-filled:before {
  content: "\eb06";
}
.vaylens-icon-filter-add-outlined:before {
  content: "\eb07";
}
.vaylens-icon-filter-check-filled:before {
  content: "\eb08";
}
.vaylens-icon-filter-check-outlined:before {
  content: "\eb09";
}
.vaylens-icon-filter-edit-filled:before {
  content: "\eb0a";
}
.vaylens-icon-filter-edit-outlined:before {
  content: "\eb0b";
}
.vaylens-icon-filter-filled:before {
  content: "\eb0c";
}
.vaylens-icon-filter-outlined:before {
  content: "\eb0d";
}
.vaylens-icon-filter-remove-filled:before {
  content: "\eb0e";
}
.vaylens-icon-filter-remove-outlined:before {
  content: "\eb0f";
}
.vaylens-icon-filter-search-filled:before {
  content: "\eb10";
}
.vaylens-icon-filter-search-outlined:before {
  content: "\eb11";
}
.vaylens-icon-filter-sq-filled:before {
  content: "\eb12";
}
.vaylens-icon-filter-sq-outlined:before {
  content: "\eb13";
}
.vaylens-icon-flag-2-filled:before {
  content: "\eb14";
}
.vaylens-icon-flag-2-outlined:before {
  content: "\eb15";
}
.vaylens-icon-flag-filled:before {
  content: "\eb16";
}
.vaylens-icon-flag-outlined:before {
  content: "\eb17";
}
.vaylens-icon-flas-dis-filled:before {
  content: "\eb18";
}
.vaylens-icon-flas-dis-outlined:before {
  content: "\eb19";
}
.vaylens-icon-flash-circle-filled:before {
  content: "\eb1a";
}
.vaylens-icon-flash-circle-outlined:before {
  content: "\eb1b";
}
.vaylens-icon-flash-filled:before {
  content: "\eb1c";
}
.vaylens-icon-flash-outlined:before {
  content: "\eb1d";
}
.vaylens-icon-fog-filled:before {
  content: "\eb1e";
}
.vaylens-icon-fog-outlined:before {
  content: "\eb1f";
}
.vaylens-icon-folder-1-filled:before {
  content: "\eb20";
}
.vaylens-icon-folder-1-open-filled:before {
  content: "\eb21";
}
.vaylens-icon-folder-1-open-outlined:before {
  content: "\eb22";
}
.vaylens-icon-folder-1-outlined:before {
  content: "\eb23";
}
.vaylens-icon-folder-2-add-filled:before {
  content: "\eb24";
}
.vaylens-icon-folder-2-add-outlined:before {
  content: "\eb25";
}
.vaylens-icon-folder-2-cloud-filled:before {
  content: "\eb26";
}
.vaylens-icon-folder-2-cloud-outlined:before {
  content: "\eb27";
}
.vaylens-icon-folder-2-favorite-filled:before {
  content: "\eb28";
}
.vaylens-icon-folder-2-favorite-outlined:before {
  content: "\eb29";
}
.vaylens-icon-folder-2-filled:before {
  content: "\eb2a";
}
.vaylens-icon-folder-2-minus-filled:before {
  content: "\eb2b";
}
.vaylens-icon-folder-2-minus-outlined:before {
  content: "\eb2c";
}
.vaylens-icon-folder-2-outlined:before {
  content: "\eb2d";
}
.vaylens-icon-folder-2-remove-filled:before {
  content: "\eb2e";
}
.vaylens-icon-folder-2-remove-outlined:before {
  content: "\eb2f";
}
.vaylens-icon-folder-shared-filled:before {
  content: "\eb30";
}
.vaylens-icon-folder-shared-outlined:before {
  content: "\eb31";
}
.vaylens-icon-forbidden-2-filled:before {
  content: "\eb32";
}
.vaylens-icon-forbidden-2-outlined:before {
  content: "\eb33";
}
.vaylens-icon-forbidden-filled:before {
  content: "\eb34";
}
.vaylens-icon-forbidden-outlined:before {
  content: "\eb35";
}
.vaylens-icon-format-circle-filled:before {
  content: "\eb36";
}
.vaylens-icon-format-circle-outlined:before {
  content: "\eb37";
}
.vaylens-icon-format-sq-filled:before {
  content: "\eb38";
}
.vaylens-icon-format-sq-outlined:before {
  content: "\eb39";
}
.vaylens-icon-forward-10s-filled:before {
  content: "\eb3a";
}
.vaylens-icon-forward-10s-outlined:before {
  content: "\eb3b";
}
.vaylens-icon-forward-15s-filled:before {
  content: "\eb3c";
}
.vaylens-icon-forward-15s-outlined:before {
  content: "\eb3d";
}
.vaylens-icon-forward-5s-filled:before {
  content: "\eb3e";
}
.vaylens-icon-forward-5s-outlined:before {
  content: "\eb3f";
}
.vaylens-icon-forward-filled:before {
  content: "\eb40";
}
.vaylens-icon-forward-outlined:before {
  content: "\eb41";
}
.vaylens-icon-gallery-filled:before {
  content: "\eb42";
}
.vaylens-icon-gallery-outlined:before {
  content: "\eb43";
}
.vaylens-icon-gameboy-filled:before {
  content: "\eb44";
}
.vaylens-icon-gameboy-outlined:before {
  content: "\eb45";
}
.vaylens-icon-gas-station-filled:before {
  content: "\eb46";
}
.vaylens-icon-gas-station-outlined:before {
  content: "\eb47";
}
.vaylens-icon-gift-filled:before {
  content: "\eb48";
}
.vaylens-icon-gift-outlined:before {
  content: "\eb49";
}
.vaylens-icon-glass-filled:before {
  content: "\eb4a";
}
.vaylens-icon-glass-outlined:before {
  content: "\eb4b";
}
.vaylens-icon-glasses-filled:before {
  content: "\eb4c";
}
.vaylens-icon-glasses-outlined:before {
  content: "\eb4d";
}
.vaylens-icon-globe-filled:before {
  content: "\eb4e";
}
.vaylens-icon-globe-outlined:before {
  content: "\eb4f";
}
.vaylens-icon-gps-filled:before {
  content: "\eb50";
}
.vaylens-icon-gps-outlined:before {
  content: "\eb51";
}
.vaylens-icon-graph-filled:before {
  content: "\eb52";
}
.vaylens-icon-graph-outlined:before {
  content: "\eb53";
}
.vaylens-icon-grid-1-filled:before {
  content: "\eb54";
}
.vaylens-icon-grid-1-outlined:before {
  content: "\eb55";
}
.vaylens-icon-grid-2-filled:before {
  content: "\eb56";
}
.vaylens-icon-grid-2-outlined:before {
  content: "\eb57";
}
.vaylens-icon-grid-3-filled:before {
  content: "\eb58";
}
.vaylens-icon-grid-3-outlined:before {
  content: "\eb59";
}
.vaylens-icon-grid-4-filled:before {
  content: "\eb5a";
}
.vaylens-icon-grid-4-outlined:before {
  content: "\eb5b";
}
.vaylens-icon-grid-5-filled:before {
  content: "\eb5c";
}
.vaylens-icon-grid-5-outlined:before {
  content: "\eb5d";
}
.vaylens-icon-grid-6-filled:before {
  content: "\eb5e";
}
.vaylens-icon-grid-6-outlined:before {
  content: "\eb5f";
}
.vaylens-icon-grid-7-filled:before {
  content: "\eb60";
}
.vaylens-icon-grid-7-outlined:before {
  content: "\eb61";
}
.vaylens-icon-grid-8-filled:before {
  content: "\eb62";
}
.vaylens-icon-grid-8-outlined:before {
  content: "\eb63";
}
.vaylens-icon-grid-9-filled:before {
  content: "\eb64";
}
.vaylens-icon-grid-9-outlined:before {
  content: "\eb65";
}
.vaylens-icon-hashtag-filled:before {
  content: "\eb66";
}
.vaylens-icon-hashtag-outlined:before {
  content: "\eb67";
}
.vaylens-icon-headphone-1-filled:before {
  content: "\eb68";
}
.vaylens-icon-headphone-1-outlined:before {
  content: "\eb69";
}
.vaylens-icon-headphone-2-filled:before {
  content: "\eb6a";
}
.vaylens-icon-headphone-2-outlined:before {
  content: "\eb6b";
}
.vaylens-icon-headphone-3-filled:before {
  content: "\eb6c";
}
.vaylens-icon-headphone-3-outlined:before {
  content: "\eb6d";
}
.vaylens-icon-headphone-4-filled:before {
  content: "\eb6e";
}
.vaylens-icon-headphone-4-outlined:before {
  content: "\eb6f";
}
.vaylens-icon-headphone-5-filled:before {
  content: "\eb70";
}
.vaylens-icon-headphone-5-outlined:before {
  content: "\eb71";
}
.vaylens-icon-heart-filled:before {
  content: "\eb72";
}
.vaylens-icon-heart-outlined:before {
  content: "\eb73";
}
.vaylens-icon-help-filled:before {
  content: "\eb74";
}
.vaylens-icon-help-outlined:before {
  content: "\eb75";
}
.vaylens-icon-help-outlined1:before {
  content: "\ee0b";
}
.vaylens-icon-hide-filled:before {
  content: "\eb76";
}
.vaylens-icon-hide-outlined:before {
  content: "\eb77";
}
.vaylens-icon-hierarchy-1-filled:before {
  content: "\eb78";
}
.vaylens-icon-hierarchy-1-outlined:before {
  content: "\eb79";
}
.vaylens-icon-hierarchy-2-filled:before {
  content: "\eb7a";
}
.vaylens-icon-hierarchy-2-outlined:before {
  content: "\eb7b";
}
.vaylens-icon-home-filled:before {
  content: "\eb7c";
}
.vaylens-icon-home-outlined:before {
  content: "\eb7d";
}
.vaylens-icon-home-penta-filled:before {
  content: "\eb7e";
}
.vaylens-icon-home-penta-outlined:before {
  content: "\eb7f";
}
.vaylens-icon-home-security-filled:before {
  content: "\eb80";
}
.vaylens-icon-home-security-outlined:before {
  content: "\eb81";
}
.vaylens-icon-home-smart-filled:before {
  content: "\eb82";
}
.vaylens-icon-home-smart-outlined:before {
  content: "\eb83";
}
.vaylens-icon-home-wifi-filled:before {
  content: "\eb84";
}
.vaylens-icon-home-wifi-outlined:before {
  content: "\eb85";
}
.vaylens-icon-ic-auth-direct-payment-filled:before {
  content: "\eb86";
}
.vaylens-icon-ic-auth-direct-payment-outlined:before {
  content: "\eb87";
}
.vaylens-icon-ic-auth-eoperate-filled:before {
  content: "\eb88";
}
.vaylens-icon-ic-auth-eoperate-outlined:before {
  content: "\eb89";
}
.vaylens-icon-ic-auth-local-filled:before {
  content: "\eb8a";
}
.vaylens-icon-ic-auth-local-outlined:before {
  content: "\eb8b";
}
.vaylens-icon-ic-auth-mobile-phone-filled:before {
  content: "\eb8c";
}
.vaylens-icon-ic-auth-mobile-phone-outlined:before {
  content: "\eb8d";
}
.vaylens-icon-ic-auth-no-filled:before {
  content: "\eb8e";
}
.vaylens-icon-ic-auth-no-outlined:before {
  content: "\eb8f";
}
.vaylens-icon-ic-auth-ocpi-cpo-filled:before {
  content: "\eb90";
}
.vaylens-icon-ic-auth-ocpi-cpo-outlined:before {
  content: "\eb91";
}
.vaylens-icon-ic-auth-remote-filled:before {
  content: "\eb92";
}
.vaylens-icon-ic-auth-remote-outlined:before {
  content: "\eb93";
}
.vaylens-icon-ic-chevron-down:before {
  content: "\eb94";
}
.vaylens-icon-ic-chevron-left:before {
  content: "\eb95";
}
.vaylens-icon-ic-chevron-right:before {
  content: "\eb96";
}
.vaylens-icon-ic-chevron-up:before {
  content: "\eb97";
}
.vaylens-icon-ic-collapse-menu-collapse:before {
  content: "\eb98";
}
.vaylens-icon-ic-collapse-menu-extend:before {
  content: "\eb99";
}
.vaylens-icon-ic-dropdown-down:before {
  content: "\eb9a";
}
.vaylens-icon-ic-dropdown-up:before {
  content: "\eb9b";
}
.vaylens-icon-ic-fleet-charging-duotone:before {
  content: "\eb9c";
}
.vaylens-icon-ic-fleet-charging-outlined:before {
  content: "\eb9d";
}
.vaylens-icon-ic-ocpp-parameters-outlined:before {
  content: "\ee0f";
}
.vaylens-icon-image-filled:before {
  content: "\eb9e";
}
.vaylens-icon-image-outlined:before {
  content: "\eb9f";
}
.vaylens-icon-import-circle-filled:before {
  content: "\eba0";
}
.vaylens-icon-import-circle-outlined:before {
  content: "\eba1";
}
.vaylens-icon-import-sq-filled:before {
  content: "\eba2";
}
.vaylens-icon-import-sq-outlined:before {
  content: "\eba3";
}
.vaylens-icon-info-circle-filled:before {
  content: "\eba4";
}
.vaylens-icon-info-circle-outlined:before {
  content: "\eba5";
}
.vaylens-icon-info-filled:before {
  content: "\eba6";
}
.vaylens-icon-info-outlined:before {
  content: "\eba7";
}
.vaylens-icon-joystick-filled:before {
  content: "\eba8";
}
.vaylens-icon-joystick-outlined:before {
  content: "\eba9";
}
.vaylens-icon-judge-filled:before {
  content: "\ebaa";
}
.vaylens-icon-judge-outlined:before {
  content: "\ebab";
}
.vaylens-icon-justify-center-filled:before {
  content: "\ebac";
}
.vaylens-icon-justify-center-outlined:before {
  content: "\ebad";
}
.vaylens-icon-justify-firstline-filled:before {
  content: "\ebae";
}
.vaylens-icon-justify-firstline-outlined:before {
  content: "\ebaf";
}
.vaylens-icon-justify-left-filled:before {
  content: "\ebb0";
}
.vaylens-icon-justify-left-outlined:before {
  content: "\ebb1";
}
.vaylens-icon-justify-right-filled:before {
  content: "\ebb2";
}
.vaylens-icon-justify-right-outlined:before {
  content: "\ebb3";
}
.vaylens-icon-kanban-filled:before {
  content: "\ebb4";
}
.vaylens-icon-kanban-outlined:before {
  content: "\ebb5";
}
.vaylens-icon-lamp-filled:before {
  content: "\ebb6";
}
.vaylens-icon-lamp-outlined:before {
  content: "\ebb7";
}
.vaylens-icon-language-filled:before {
  content: "\ebb8";
}
.vaylens-icon-language-outlined:before {
  content: "\ebb9";
}
.vaylens-icon-layers-filled:before {
  content: "\ebba";
}
.vaylens-icon-layers-outlined:before {
  content: "\ebbb";
}
.vaylens-icon-legal-notice-outlined:before {
  content: "\ee0c";
}
.vaylens-icon-level-filled:before {
  content: "\ebbc";
}
.vaylens-icon-level-outlined:before {
  content: "\ebbd";
}
.vaylens-icon-like-filled:before {
  content: "\ebbe";
}
.vaylens-icon-like-outlined:before {
  content: "\ebbf";
}
.vaylens-icon-link-1-filled:before {
  content: "\ebc0";
}
.vaylens-icon-link-1-outlined:before {
  content: "\ebc1";
}
.vaylens-icon-link-2-filled:before {
  content: "\ebc2";
}
.vaylens-icon-link-2-outlined:before {
  content: "\ebc3";
}
.vaylens-icon-link-3-circle-filled:before {
  content: "\ebc4";
}
.vaylens-icon-link-3-circle-outlined:before {
  content: "\ebc5";
}
.vaylens-icon-link-3-filled:before {
  content: "\ebc6";
}
.vaylens-icon-link-3-outlined:before {
  content: "\ebc7";
}
.vaylens-icon-link-3-sq-filled:before {
  content: "\ebc8";
}
.vaylens-icon-link-3-sq-outlined:before {
  content: "\ebc9";
}
.vaylens-icon-list-check-filled:before {
  content: "\ebca";
}
.vaylens-icon-list-check-outlined:before {
  content: "\ebcb";
}
.vaylens-icon-list-check-sq-filled:before {
  content: "\ebcc";
}
.vaylens-icon-list-check-sq-outlined:before {
  content: "\ebcd";
}
.vaylens-icon-location-add-filled:before {
  content: "\ebce";
}
.vaylens-icon-location-add-outlined:before {
  content: "\ebcf";
}
.vaylens-icon-location-check-filled:before {
  content: "\ebd0";
}
.vaylens-icon-location-check-outlined:before {
  content: "\ebd1";
}
.vaylens-icon-location-delete-filled:before {
  content: "\ebd2";
}
.vaylens-icon-location-delete-outlined:before {
  content: "\ebd3";
}
.vaylens-icon-location-disabled-filled:before {
  content: "\ebd4";
}
.vaylens-icon-location-disabled-outlined:before {
  content: "\ebd5";
}
.vaylens-icon-location-filled:before {
  content: "\ebd6";
}
.vaylens-icon-location-outlined:before {
  content: "\ebd7";
}
.vaylens-icon-location-remove-filled:before {
  content: "\ebd8";
}
.vaylens-icon-location-remove-outlined:before {
  content: "\ebd9";
}
.vaylens-icon-locked-false-outlined:before {
  content: "\ee10";
}
.vaylens-icon-locked-true-outlined:before {
  content: "\ee11";
}
.vaylens-icon-login-1-filled:before {
  content: "\ebda";
}
.vaylens-icon-login-1-outlined:before {
  content: "\ebdb";
}
.vaylens-icon-login-2-filled:before {
  content: "\ebdc";
}
.vaylens-icon-login-2-outlined:before {
  content: "\ebdd";
}
.vaylens-icon-logout-1-filled:before {
  content: "\ebde";
}
.vaylens-icon-logout-1-outlined:before {
  content: "\ebdf";
}
.vaylens-icon-logout-2-filled:before {
  content: "\ebe0";
}
.vaylens-icon-logout-2-outlined:before {
  content: "\ebe1";
}
.vaylens-icon-magic-pen-filled:before {
  content: "\ebe2";
}
.vaylens-icon-magic-pen-outlined:before {
  content: "\ebe3";
}
.vaylens-icon-mailbox-1-filled:before {
  content: "\ebe4";
}
.vaylens-icon-mailbox-1-inbox-filled:before {
  content: "\ebe5";
}
.vaylens-icon-mailbox-1-inbox-outlined:before {
  content: "\ebe6";
}
.vaylens-icon-mailbox-1-messages-filled:before {
  content: "\ebe7";
}
.vaylens-icon-mailbox-1-messages-outlined:before {
  content: "\ebe8";
}
.vaylens-icon-mailbox-1-outbox-filled:before {
  content: "\ebe9";
}
.vaylens-icon-mailbox-1-outbox-outlined:before {
  content: "\ebea";
}
.vaylens-icon-mailbox-1-outlined:before {
  content: "\ebeb";
}
.vaylens-icon-mailbox-2-filled:before {
  content: "\ebec";
}
.vaylens-icon-mailbox-2-inbox-filled:before {
  content: "\ebed";
}
.vaylens-icon-mailbox-2-inbox-outlined:before {
  content: "\ebee";
}
.vaylens-icon-mailbox-2-messages-filled:before {
  content: "\ebef";
}
.vaylens-icon-mailbox-2-messages-outlined:before {
  content: "\ebf0";
}
.vaylens-icon-mailbox-2-outbox-filled:before {
  content: "\ebf1";
}
.vaylens-icon-mailbox-2-outbox-outlined:before {
  content: "\ebf2";
}
.vaylens-icon-mailbox-2-outlined:before {
  content: "\ebf3";
}
.vaylens-icon-map-filled:before {
  content: "\ebf4";
}
.vaylens-icon-map-outlined:before {
  content: "\ebf5";
}
.vaylens-icon-maximaze-2-filled:before {
  content: "\ebf6";
}
.vaylens-icon-maximaze-2-outlined:before {
  content: "\ebf7";
}
.vaylens-icon-maximize-filled:before {
  content: "\ebf8";
}
.vaylens-icon-maximize-outlined:before {
  content: "\ebf9";
}
.vaylens-icon-maximize-sq-filled:before {
  content: "\ebfa";
}
.vaylens-icon-maximize-sq-outlined:before {
  content: "\ebfb";
}
.vaylens-icon-medal-filled:before {
  content: "\ebfc";
}
.vaylens-icon-medal-outlined:before {
  content: "\ebfd";
}
.vaylens-icon-menu-filled:before {
  content: "\ebfe";
}
.vaylens-icon-menu-filled-2:before {
  content: "\ebff";
}
.vaylens-icon-menu-filled-3:before {
  content: "\ec00";
}
.vaylens-icon-menu-outlined:before {
  content: "\ec01";
}
.vaylens-icon-menu-outlined-2:before {
  content: "\ec02";
}
.vaylens-icon-menu-outlined-3:before {
  content: "\ec03";
}
.vaylens-icon-messages-1-filled:before {
  content: "\ec04";
}
.vaylens-icon-messages-1-outlined:before {
  content: "\ec05";
}
.vaylens-icon-messages-2-filled:before {
  content: "\ec06";
}
.vaylens-icon-messages-2-outlined:before {
  content: "\ec07";
}
.vaylens-icon-messages-3-add-filled:before {
  content: "\ec08";
}
.vaylens-icon-messages-3-add-outlined:before {
  content: "\ec09";
}
.vaylens-icon-messages-3-edit-filled:before {
  content: "\ec0a";
}
.vaylens-icon-messages-3-edit-outlined:before {
  content: "\ec0b";
}
.vaylens-icon-messages-3-question-filled:before {
  content: "\ec0c";
}
.vaylens-icon-messages-3-question-outlined:before {
  content: "\ec0d";
}
.vaylens-icon-messages-3-remove-filled:before {
  content: "\ec0e";
}
.vaylens-icon-messages-3-remove-outlined:before {
  content: "\ec0f";
}
.vaylens-icon-messages-3-text-filled:before {
  content: "\ec10";
}
.vaylens-icon-messages-3-text-outlined:before {
  content: "\ec11";
}
.vaylens-icon-mic-1-disabled-filled:before {
  content: "\ec12";
}
.vaylens-icon-mic-1-disabled-outlined:before {
  content: "\ec13";
}
.vaylens-icon-mic-1-filled:before {
  content: "\ec14";
}
.vaylens-icon-mic-1-outlined:before {
  content: "\ec15";
}
.vaylens-icon-mic-2-disabled-filled:before {
  content: "\ec16";
}
.vaylens-icon-mic-2-disabled-outlined:before {
  content: "\ec17";
}
.vaylens-icon-mic-2-filled:before {
  content: "\ec18";
}
.vaylens-icon-mic-2-outlined:before {
  content: "\ec19";
}
.vaylens-icon-milk-filled:before {
  content: "\ec1a";
}
.vaylens-icon-milk-outlined:before {
  content: "\ec1b";
}
.vaylens-icon-minus-circle-filled:before {
  content: "\ec1c";
}
.vaylens-icon-minus-circle-outlined:before {
  content: "\ec1d";
}
.vaylens-icon-minus-filled:before {
  content: "\ec1e";
}
.vaylens-icon-minus-outlined:before {
  content: "\ec1f";
}
.vaylens-icon-minus-sq-filled:before {
  content: "\ec20";
}
.vaylens-icon-minus-sq-outlined:before {
  content: "\ec21";
}
.vaylens-icon-mirroring-filled:before {
  content: "\ec22";
}
.vaylens-icon-mirroring-outlined:before {
  content: "\ec23";
}
.vaylens-icon-mobile-filled:before {
  content: "\ec24";
}
.vaylens-icon-mobile-outlined:before {
  content: "\ec25";
}
.vaylens-icon-money-1-filled:before {
  content: "\ec26";
}
.vaylens-icon-money-1-outlined:before {
  content: "\ec27";
}
.vaylens-icon-money-2-filled:before {
  content: "\ec28";
}
.vaylens-icon-money-2-outlined:before {
  content: "\ec29";
}
.vaylens-icon-money-3-filled:before {
  content: "\ec2a";
}
.vaylens-icon-money-3-outlined:before {
  content: "\ec2b";
}
.vaylens-icon-money-add-filled:before {
  content: "\ec2c";
}
.vaylens-icon-money-add-outlined:before {
  content: "\ec2d";
}
.vaylens-icon-money-cancel-filled:before {
  content: "\ec2e";
}
.vaylens-icon-money-cancel-outlined:before {
  content: "\ec2f";
}
.vaylens-icon-money-check-filled:before {
  content: "\ec30";
}
.vaylens-icon-money-check-outlined:before {
  content: "\ec31";
}
.vaylens-icon-money-circle-filled:before {
  content: "\ec32";
}
.vaylens-icon-money-circle-outlined:before {
  content: "\ec33";
}
.vaylens-icon-money-processing-filled:before {
  content: "\ec34";
}
.vaylens-icon-money-processing-outlined:before {
  content: "\ec35";
}
.vaylens-icon-money-recieve-filled:before {
  content: "\ec36";
}
.vaylens-icon-money-recieve-outlined:before {
  content: "\ec37";
}
.vaylens-icon-money-send-filled:before {
  content: "\ec38";
}
.vaylens-icon-money-send-outlined:before {
  content: "\ec39";
}
.vaylens-icon-money-sq-filled:before {
  content: "\ec3a";
}
.vaylens-icon-money-sq-outlined:before {
  content: "\ec3b";
}
.vaylens-icon-moon-filled:before {
  content: "\ec3c";
}
.vaylens-icon-moon-outlined:before {
  content: "\ec3d";
}
.vaylens-icon-more-1-filled:before {
  content: "\ec3e";
}
.vaylens-icon-more-1-outlined:before {
  content: "\ec3f";
}
.vaylens-icon-more-2-filled:before {
  content: "\ec40";
}
.vaylens-icon-more-2-outlined:before {
  content: "\ec41";
}
.vaylens-icon-more-circle-filled:before {
  content: "\ec42";
}
.vaylens-icon-more-circle-outlined:before {
  content: "\ec43";
}
.vaylens-icon-more-filled:before {
  content: "\ec44";
}
.vaylens-icon-more-outlined:before {
  content: "\ec45";
}
.vaylens-icon-more-sq-filled:before {
  content: "\ec46";
}
.vaylens-icon-more-sq-outlined:before {
  content: "\ec47";
}
.vaylens-icon-mouse-circle-filled:before {
  content: "\ec48";
}
.vaylens-icon-mouse-circle-outlined:before {
  content: "\ec49";
}
.vaylens-icon-mouse-filled:before {
  content: "\ec4a";
}
.vaylens-icon-mouse-filled-2:before {
  content: "\ec4b";
}
.vaylens-icon-mouse-outlined:before {
  content: "\ec4c";
}
.vaylens-icon-mouse-outlined-2:before {
  content: "\ec4d";
}
.vaylens-icon-mouse-sq-filled:before {
  content: "\ec4e";
}
.vaylens-icon-mouse-sq-outlined:before {
  content: "\ec4f";
}
.vaylens-icon-music-archive-filled:before {
  content: "\ec50";
}
.vaylens-icon-music-archive-outlined:before {
  content: "\ec51";
}
.vaylens-icon-music-filled:before {
  content: "\ec52";
}
.vaylens-icon-music-note-1-filled:before {
  content: "\ec53";
}
.vaylens-icon-music-note-1-outlined:before {
  content: "\ec54";
}
.vaylens-icon-music-note-2-filled:before {
  content: "\ec55";
}
.vaylens-icon-music-note-2-outlined:before {
  content: "\ec56";
}
.vaylens-icon-music-outlined:before {
  content: "\ec57";
}
.vaylens-icon-music-repeat-1-filled:before {
  content: "\ec58";
}
.vaylens-icon-music-repeat-1-outlined:before {
  content: "\ec59";
}
.vaylens-icon-music-repeat-filled:before {
  content: "\ec5a";
}
.vaylens-icon-music-repeat-outlined:before {
  content: "\ec5b";
}
.vaylens-icon-music-sq-filled:before {
  content: "\ec5c";
}
.vaylens-icon-music-sq-outlined:before {
  content: "\ec5d";
}
.vaylens-icon-next-filled:before {
  content: "\ec5e";
}
.vaylens-icon-next-outlined:before {
  content: "\ec5f";
}
.vaylens-icon-note-1-add-filled:before {
  content: "\ec60";
}
.vaylens-icon-note-1-add-outlined:before {
  content: "\ec61";
}
.vaylens-icon-note-1-filled:before {
  content: "\ec62";
}
.vaylens-icon-note-1-outlined:before {
  content: "\ec63";
}
.vaylens-icon-note-2-add-filled:before {
  content: "\ec64";
}
.vaylens-icon-note-2-add-outlined:before {
  content: "\ec65";
}
.vaylens-icon-note-2-filled:before {
  content: "\ec66";
}
.vaylens-icon-note-2-outlined:before {
  content: "\ec67";
}
.vaylens-icon-notification-1-filled:before {
  content: "\ec68";
}
.vaylens-icon-notification-1-outlined:before {
  content: "\ec69";
}
.vaylens-icon-notification-2-filled:before {
  content: "\ec6a";
}
.vaylens-icon-notification-2-outlined:before {
  content: "\ec6b";
}
.vaylens-icon-outlined:before {
  content: "\ec6c";
}
.vaylens-icon-paint-bucket-1-filled:before {
  content: "\ec6d";
}
.vaylens-icon-paint-bucket-1-outlined:before {
  content: "\ec6e";
}
.vaylens-icon-paint-bucket-2-filled:before {
  content: "\ec6f";
}
.vaylens-icon-paint-bucket-2-outlined:before {
  content: "\ec70";
}
.vaylens-icon-palette-filled:before {
  content: "\ec71";
}
.vaylens-icon-palette-outlined:before {
  content: "\ec72";
}
.vaylens-icon-paperclip-1-filled:before {
  content: "\ec73";
}
.vaylens-icon-paperclip-1-outlined:before {
  content: "\ec74";
}
.vaylens-icon-paperclip-2-filled:before {
  content: "\ec75";
}
.vaylens-icon-paperclip-2-outlined:before {
  content: "\ec76";
}
.vaylens-icon-pause-circle-filled:before {
  content: "\ec77";
}
.vaylens-icon-pause-circle-outlined:before {
  content: "\ec78";
}
.vaylens-icon-pen-tool-1-filled:before {
  content: "\ec79";
}
.vaylens-icon-pen-tool-1-outlined:before {
  content: "\ec7a";
}
.vaylens-icon-pen-tool-2-filled:before {
  content: "\ec7b";
}
.vaylens-icon-pen-tool-2-outlined:before {
  content: "\ec7c";
}
.vaylens-icon-percent-circle-filled:before {
  content: "\ec7d";
}
.vaylens-icon-percent-circle-outlined:before {
  content: "\ec7e";
}
.vaylens-icon-percent-sq-filled:before {
  content: "\ec7f";
}
.vaylens-icon-percent-sq-outlined:before {
  content: "\ec80";
}
.vaylens-icon-personal-card-filled:before {
  content: "\ec81";
}
.vaylens-icon-personal-card-outlined:before {
  content: "\ec82";
}
.vaylens-icon-phone-1-filled:before {
  content: "\ec83";
}
.vaylens-icon-phone-1-outlined:before {
  content: "\ec84";
}
.vaylens-icon-phone-2-filled:before {
  content: "\ec85";
}
.vaylens-icon-phone-2-outlined:before {
  content: "\ec86";
}
.vaylens-icon-play-filled:before {
  content: "\ec87";
}
.vaylens-icon-play-outlined:before {
  content: "\ec88";
}
.vaylens-icon-playlist-filled:before {
  content: "\ec89";
}
.vaylens-icon-playlist-filled-1:before {
  content: "\ec8a";
}
.vaylens-icon-playlist-outlined:before {
  content: "\ec8b";
}
.vaylens-icon-playlist-outlined-1:before {
  content: "\ec8c";
}
.vaylens-icon-previous-filled:before {
  content: "\ec8d";
}
.vaylens-icon-previous-outlined:before {
  content: "\ec8e";
}
.vaylens-icon-printer-disabled-filled:before {
  content: "\ec8f";
}
.vaylens-icon-printer-disabled-outlined:before {
  content: "\ec90";
}
.vaylens-icon-printer-filled:before {
  content: "\ec91";
}
.vaylens-icon-printer-outlined:before {
  content: "\ec92";
}
.vaylens-icon-privacy-policies-outlined:before {
  content: "\ee0e";
}
.vaylens-icon-profile-add-filled:before {
  content: "\ec93";
}
.vaylens-icon-profile-add-outlined:before {
  content: "\ec94";
}
.vaylens-icon-profile-check-filled:before {
  content: "\ec95";
}
.vaylens-icon-profile-check-outlined:before {
  content: "\ec96";
}
.vaylens-icon-profile-delete-filled:before {
  content: "\ec97";
}
.vaylens-icon-profile-delete-outlined:before {
  content: "\ec98";
}
.vaylens-icon-profile-filled:before {
  content: "\ec99";
}
.vaylens-icon-profile-group-filled:before {
  content: "\ec9a";
}
.vaylens-icon-profile-group-outlined:before {
  content: "\ec9b";
}
.vaylens-icon-profile-network-filled:before {
  content: "\ec9c";
}
.vaylens-icon-profile-network-outlined:before {
  content: "\ec9d";
}
.vaylens-icon-profile-outlined:before {
  content: "\ec9e";
}
.vaylens-icon-profile-remove-filled:before {
  content: "\ec9f";
}
.vaylens-icon-profile-remove-outlined:before {
  content: "\eca0";
}
.vaylens-icon-radar-1-filled:before {
  content: "\eca1";
}
.vaylens-icon-radar-1-outlined:before {
  content: "\eca2";
}
.vaylens-icon-radar-2-filled:before {
  content: "\eca3";
}
.vaylens-icon-radar-2-outlined:before {
  content: "\eca4";
}
.vaylens-icon-radio-filled:before {
  content: "\eca5";
}
.vaylens-icon-radio-outlined:before {
  content: "\eca6";
}
.vaylens-icon-rain-filled:before {
  content: "\eca7";
}
.vaylens-icon-rain-outlined:before {
  content: "\eca8";
}
.vaylens-icon-ranking-1-filled:before {
  content: "\eca9";
}
.vaylens-icon-ranking-1-outlined:before {
  content: "\ecaa";
}
.vaylens-icon-ranking-2-filled:before {
  content: "\ecab";
}
.vaylens-icon-ranking-2-outlined:before {
  content: "\ecac";
}
.vaylens-icon-ranking-filled:before {
  content: "\ecad";
}
.vaylens-icon-ranking-outlined:before {
  content: "\ecae";
}
.vaylens-icon-reciept-1-filled:before {
  content: "\ecaf";
}
.vaylens-icon-reciept-1-outlined:before {
  content: "\ecb0";
}
.vaylens-icon-reciept-2-filled:before {
  content: "\ecb1";
}
.vaylens-icon-reciept-2-outlined:before {
  content: "\ecb2";
}
.vaylens-icon-reciept-3-filled:before {
  content: "\ecb3";
}
.vaylens-icon-reciept-3-outlined:before {
  content: "\ecb4";
}
.vaylens-icon-reciept-add-filled:before {
  content: "\ecb5";
}
.vaylens-icon-reciept-add-outlined:before {
  content: "\ecb6";
}
.vaylens-icon-reciept-edit-filled:before {
  content: "\ecb7";
}
.vaylens-icon-reciept-edit-outlined:before {
  content: "\ecb8";
}
.vaylens-icon-reciept-filled:before {
  content: "\ecb9";
}
.vaylens-icon-reciept-item-filled:before {
  content: "\ecba";
}
.vaylens-icon-reciept-item-outlined:before {
  content: "\ecbb";
}
.vaylens-icon-reciept-outlined:before {
  content: "\ecbc";
}
.vaylens-icon-reciept-percent-filled:before {
  content: "\ecbd";
}
.vaylens-icon-reciept-percent-outlined:before {
  content: "\ecbe";
}
.vaylens-icon-reciept-remove-filled:before {
  content: "\ecbf";
}
.vaylens-icon-reciept-remove-outlined:before {
  content: "\ecc0";
}
.vaylens-icon-reciept-search-filled:before {
  content: "\ecc1";
}
.vaylens-icon-reciept-search-outlined:before {
  content: "\ecc2";
}
.vaylens-icon-recieve-sq-filled:before {
  content: "\ecc3";
}
.vaylens-icon-recieve-sq-outlined:before {
  content: "\ecc4";
}
.vaylens-icon-record-filled:before {
  content: "\ecc5";
}
.vaylens-icon-record-outlined:before {
  content: "\ecc6";
}
.vaylens-icon-recover-filled:before {
  content: "\ecc7";
}
.vaylens-icon-recover-outlined:before {
  content: "\ecc8";
}
.vaylens-icon-redo-filled:before {
  content: "\ecc9";
}
.vaylens-icon-redo-outlined:before {
  content: "\ecca";
}
.vaylens-icon-redo-sq-filled:before {
  content: "\eccb";
}
.vaylens-icon-redo-sq-outlined:before {
  content: "\eccc";
}
.vaylens-icon-relation-filled:before {
  content: "\eccd";
}
.vaylens-icon-relation-outlined:before {
  content: "\ecce";
}
.vaylens-icon-repeat-circle-filled:before {
  content: "\eccf";
}
.vaylens-icon-repeat-circle-outlined:before {
  content: "\ecd0";
}
.vaylens-icon-reserve-filled:before {
  content: "\ecd1";
}
.vaylens-icon-reserve-outlined:before {
  content: "\ecd2";
}
.vaylens-icon-rotate-left-filled:before {
  content: "\ecd3";
}
.vaylens-icon-rotate-left-filled-2:before {
  content: "\ecd4";
}
.vaylens-icon-rotate-left-outlined:before {
  content: "\ecd5";
}
.vaylens-icon-rotate-left-outlined-2:before {
  content: "\ecd6";
}
.vaylens-icon-rotate-left-sq-filled:before {
  content: "\ecd7";
}
.vaylens-icon-rotate-left-sq-outlined:before {
  content: "\ecd8";
}
.vaylens-icon-rotate-refresh-circle-filled:before {
  content: "\ecd9";
}
.vaylens-icon-rotate-refresh-circle-outlined:before {
  content: "\ecda";
}
.vaylens-icon-rotate-refresh-filled:before {
  content: "\ecdb";
}
.vaylens-icon-rotate-refresh-outlined:before {
  content: "\ecdc";
}
.vaylens-icon-rotate-refresh-sq-filled:before {
  content: "\ecdd";
}
.vaylens-icon-rotate-refresh-sq-outlined:before {
  content: "\ecde";
}
.vaylens-icon-rotate-right-filled:before {
  content: "\ecdf";
}
.vaylens-icon-rotate-right-filled-2:before {
  content: "\ece0";
}
.vaylens-icon-rotate-right-outlined:before {
  content: "\ece1";
}
.vaylens-icon-rotate-right-outlined-2:before {
  content: "\ece2";
}
.vaylens-icon-rotate-right-sq-filled:before {
  content: "\ece3";
}
.vaylens-icon-rotate-right-sq-outlined:before {
  content: "\ece4";
}
.vaylens-icon-route-arrows-filled:before {
  content: "\ece5";
}
.vaylens-icon-route-arrows-outlined:before {
  content: "\ece6";
}
.vaylens-icon-route-filled:before {
  content: "\ece7";
}
.vaylens-icon-route-outlined:before {
  content: "\ece8";
}
.vaylens-icon-routing-1-filled:before {
  content: "\ece9";
}
.vaylens-icon-routing-1-outlined:before {
  content: "\ecea";
}
.vaylens-icon-routing-2-filled:before {
  content: "\eceb";
}
.vaylens-icon-routing-2-outlined:before {
  content: "\ecec";
}
.vaylens-icon-row-horizontal-filled:before {
  content: "\eced";
}
.vaylens-icon-row-horizontal-outlined:before {
  content: "\ecee";
}
.vaylens-icon-row-vertial-filled:before {
  content: "\ecef";
}
.vaylens-icon-row-vertial-outlined:before {
  content: "\ecf0";
}
.vaylens-icon-ruler-filled:before {
  content: "\ecf1";
}
.vaylens-icon-ruler-outlined:before {
  content: "\ecf2";
}
.vaylens-icon-safe-filled:before {
  content: "\ecf3";
}
.vaylens-icon-safe-outlined:before {
  content: "\ecf4";
}
.vaylens-icon-scissors-filled:before {
  content: "\ecf5";
}
.vaylens-icon-scissors-outlined:before {
  content: "\ecf6";
}
.vaylens-icon-scr-mirroring-filled:before {
  content: "\ecf7";
}
.vaylens-icon-scr-mirroring-outlined:before {
  content: "\ecf8";
}
.vaylens-icon-screen-camera-filled:before {
  content: "\ecf9";
}
.vaylens-icon-screen-camera-outlined:before {
  content: "\ecfa";
}
.vaylens-icon-screen-filled:before {
  content: "\ecfb";
}
.vaylens-icon-screen-outlined:before {
  content: "\ecfc";
}
.vaylens-icon-search-1-filled:before {
  content: "\ecfd";
}
.vaylens-icon-search-1-outlined:before {
  content: "\ecfe";
}
.vaylens-icon-search-2-filled:before {
  content: "\ecff";
}
.vaylens-icon-search-2-outlined:before {
  content: "\ed00";
}
.vaylens-icon-send-filled:before {
  content: "\ed01";
}
.vaylens-icon-send-outlined:before {
  content: "\ed02";
}
.vaylens-icon-send-sq-filled:before {
  content: "\ed03";
}
.vaylens-icon-send-sq-outlined:before {
  content: "\ed04";
}
.vaylens-icon-send-to-back-filled:before {
  content: "\ed05";
}
.vaylens-icon-send-to-back-outlined:before {
  content: "\ed06";
}
.vaylens-icon-settings-1-filled:before {
  content: "\ed07";
}
.vaylens-icon-settings-1-outlined:before {
  content: "\ed08";
}
.vaylens-icon-settings-2-filled:before {
  content: "\ed09";
}
.vaylens-icon-settings-2-outlined:before {
  content: "\ed0a";
}
.vaylens-icon-settings-3-filled:before {
  content: "\ed0b";
}
.vaylens-icon-settings-3-outlined:before {
  content: "\ed0c";
}
.vaylens-icon-settings-4-filled:before {
  content: "\ed0d";
}
.vaylens-icon-settings-4-outlined:before {
  content: "\ed0e";
}
.vaylens-icon-settings-5-filled:before {
  content: "\ed0f";
}
.vaylens-icon-settings-5-outlined:before {
  content: "\ed10";
}
.vaylens-icon-shapes-filled:before {
  content: "\ed11";
}
.vaylens-icon-shapes-outlined:before {
  content: "\ed12";
}
.vaylens-icon-share-filled:before {
  content: "\ed13";
}
.vaylens-icon-share-outlined:before {
  content: "\ed14";
}
.vaylens-icon-ship-filled:before {
  content: "\ed15";
}
.vaylens-icon-ship-outlined:before {
  content: "\ed16";
}
.vaylens-icon-shop-filled:before {
  content: "\ed17";
}
.vaylens-icon-shop-outlined:before {
  content: "\ed18";
}
.vaylens-icon-show-filled:before {
  content: "\ed19";
}
.vaylens-icon-show-outlined:before {
  content: "\ed1a";
}
.vaylens-icon-shuffle-filled:before {
  content: "\ed1b";
}
.vaylens-icon-shuffle-outlined:before {
  content: "\ed1c";
}
.vaylens-icon-signpost-filled:before {
  content: "\ed1d";
}
.vaylens-icon-signpost-outlined:before {
  content: "\ed1e";
}
.vaylens-icon-sim-1-filled:before {
  content: "\ed1f";
}
.vaylens-icon-sim-1-outlined:before {
  content: "\ed20";
}
.vaylens-icon-sim-2-filled:before {
  content: "\ed21";
}
.vaylens-icon-sim-2-outlined:before {
  content: "\ed22";
}
.vaylens-icon-sim-3-filled:before {
  content: "\ed23";
}
.vaylens-icon-sim-3-outlined:before {
  content: "\ed24";
}
.vaylens-icon-slash-filled:before {
  content: "\ed25";
}
.vaylens-icon-slash-outlined:before {
  content: "\ed26";
}
.vaylens-icon-slider-1-horizontal-filled:before {
  content: "\ed27";
}
.vaylens-icon-slider-1-horizontal-outlined:before {
  content: "\ed28";
}
.vaylens-icon-slider-1-vertical-filled:before {
  content: "\ed29";
}
.vaylens-icon-slider-1-vertical-outlined:before {
  content: "\ed2a";
}
.vaylens-icon-slider-2-horizontal-filled:before {
  content: "\ed2b";
}
.vaylens-icon-slider-2-horizontal-outlined:before {
  content: "\ed2c";
}
.vaylens-icon-slider-2-vertical-filled:before {
  content: "\ed2d";
}
.vaylens-icon-slider-2-vertical-outlined:before {
  content: "\ed2e";
}
.vaylens-icon-slider-filled:before {
  content: "\ed2f";
}
.vaylens-icon-slider-outlined:before {
  content: "\ed30";
}
.vaylens-icon-small-chevron-down-filled:before {
  content: "\ed31";
}
.vaylens-icon-small-chevron-down-outlined:before {
  content: "\ed32";
}
.vaylens-icon-small-chevron-left-filled:before {
  content: "\ed33";
}
.vaylens-icon-small-chevron-left-outlined:before {
  content: "\ed34";
}
.vaylens-icon-small-chevron-right-filled:before {
  content: "\ed35";
}
.vaylens-icon-small-chevron-right-outlined:before {
  content: "\ed36";
}
.vaylens-icon-small-chevron-up-filled:before {
  content: "\ed37";
}
.vaylens-icon-small-chevron-up-outlined:before {
  content: "\ed38";
}
.vaylens-icon-snow-filled:before {
  content: "\ed39";
}
.vaylens-icon-snow-outlined:before {
  content: "\ed3a";
}
.vaylens-icon-sort-filled:before {
  content: "\ed3b";
}
.vaylens-icon-sort-outlined:before {
  content: "\ed3c";
}
.vaylens-icon-sound-filled:before {
  content: "\ed3d";
}
.vaylens-icon-sound-outlined:before {
  content: "\ed3e";
}
.vaylens-icon-speaker-filled:before {
  content: "\ed3f";
}
.vaylens-icon-speaker-outlined:before {
  content: "\ed40";
}
.vaylens-icon-star-filled:before {
  content: "\ed41";
}
.vaylens-icon-star-outlined:before {
  content: "\ed42";
}
.vaylens-icon-sticker-filled:before {
  content: "\ed43";
}
.vaylens-icon-sticker-outlined:before {
  content: "\ed44";
}
.vaylens-icon-stop-circle-filled:before {
  content: "\ed45";
}
.vaylens-icon-stop-circle-outlined:before {
  content: "\ed46";
}
.vaylens-icon-story-filled:before {
  content: "\ed47";
}
.vaylens-icon-story-outlined:before {
  content: "\ed48";
}
.vaylens-icon-strongbox-filled:before {
  content: "\ed49";
}
.vaylens-icon-strongbox-outlined:before {
  content: "\ed4a";
}
.vaylens-icon-subtitle-filled:before {
  content: "\ed4b";
}
.vaylens-icon-subtitle-outlined:before {
  content: "\ed4c";
}
.vaylens-icon-sun-filled:before {
  content: "\ed4d";
}
.vaylens-icon-sun-outlined:before {
  content: "\ed4e";
}
.vaylens-icon-sunny-filled:before {
  content: "\ed4f";
}
.vaylens-icon-sunny-outlined:before {
  content: "\ed50";
}
.vaylens-icon-sunny-partially-filled:before {
  content: "\ed51";
}
.vaylens-icon-sunny-partially-outlined:before {
  content: "\ed52";
}
.vaylens-icon-sunset-filled:before {
  content: "\ed53";
}
.vaylens-icon-sunset-outlined:before {
  content: "\ed54";
}
.vaylens-icon-swmart-watch-filled:before {
  content: "\ed55";
}
.vaylens-icon-swmart-watch-outlined:before {
  content: "\ed56";
}
.vaylens-icon-system-health-filled:before {
  content: "\ed57";
}
.vaylens-icon-system-health-outlined:before {
  content: "\ed58";
}
.vaylens-icon-tag-2-filled:before {
  content: "\ed59";
}
.vaylens-icon-tag-2-outlined:before {
  content: "\ed5a";
}
.vaylens-icon-tag-3-filled:before {
  content: "\ed5b";
}
.vaylens-icon-tag-3-outlined:before {
  content: "\ed5c";
}
.vaylens-icon-tag-cross-filled:before {
  content: "\ed5d";
}
.vaylens-icon-tag-cross-outlined:before {
  content: "\ed5e";
}
.vaylens-icon-tag-filled:before {
  content: "\ed5f";
}
.vaylens-icon-tag-outlined:before {
  content: "\ed60";
}
.vaylens-icon-teacher-filled:before {
  content: "\ed61";
}
.vaylens-icon-teacher-outlined:before {
  content: "\ed62";
}
.vaylens-icon-telescope-filled:before {
  content: "\ed63";
}
.vaylens-icon-telescope-outlined:before {
  content: "\ed64";
}
.vaylens-icon-terms-of-use-outlined:before {
  content: "\ee0d";
}
.vaylens-icon-thunder-filled:before {
  content: "\ed65";
}
.vaylens-icon-thunder-outlined:before {
  content: "\ed66";
}
.vaylens-icon-ticket-discount-filled:before {
  content: "\ed67";
}
.vaylens-icon-ticket-discount-outlined:before {
  content: "\ed68";
}
.vaylens-icon-ticket-favs-filled:before {
  content: "\ed69";
}
.vaylens-icon-ticket-favs-outlined:before {
  content: "\ed6a";
}
.vaylens-icon-ticket-filled:before {
  content: "\ed6b";
}
.vaylens-icon-ticket-outlined:before {
  content: "\ed6c";
}
.vaylens-icon-timer-1-filled:before {
  content: "\ed6d";
}
.vaylens-icon-timer-1-outlined:before {
  content: "\ed6e";
}
.vaylens-icon-timer-2-filled:before {
  content: "\ed6f";
}
.vaylens-icon-timer-2-outlined:before {
  content: "\ed70";
}
.vaylens-icon-timer-2-pause-filled:before {
  content: "\ed71";
}
.vaylens-icon-timer-2-pause-outlined:before {
  content: "\ed72";
}
.vaylens-icon-timer-2-start-filled:before {
  content: "\ed73";
}
.vaylens-icon-timer-2-start-outlined:before {
  content: "\ed74";
}
.vaylens-icon-toolbar-bottom-filled:before {
  content: "\ed75";
}
.vaylens-icon-toolbar-bottom-outlined:before {
  content: "\ed76";
}
.vaylens-icon-toolbar-left-filled:before {
  content: "\ed77";
}
.vaylens-icon-toolbar-left-outlined:before {
  content: "\ed78";
}
.vaylens-icon-toolbar-right-filled:before {
  content: "\ed79";
}
.vaylens-icon-toolbar-right-outlined:before {
  content: "\ed7a";
}
.vaylens-icon-toolbar-top-filled:before {
  content: "\ed7b";
}
.vaylens-icon-toolbar-top-outlined:before {
  content: "\ed7c";
}
.vaylens-icon-track-filled:before {
  content: "\ed7d";
}
.vaylens-icon-track-outlined:before {
  content: "\ed7e";
}
.vaylens-icon-transfer-filled:before {
  content: "\ed7f";
}
.vaylens-icon-transfer-outlined:before {
  content: "\ed80";
}
.vaylens-icon-translate-filled:before {
  content: "\ed81";
}
.vaylens-icon-translate-outlined:before {
  content: "\ed82";
}
.vaylens-icon-trash-filled:before {
  content: "\ed83";
}
.vaylens-icon-trash-outlined:before {
  content: "\ed84";
}
.vaylens-icon-trash-sq-filled:before {
  content: "\ed85";
}
.vaylens-icon-trash-sq-outlined:before {
  content: "\ed86";
}
.vaylens-icon-tree-filled:before {
  content: "\ed87";
}
.vaylens-icon-tree-outlined:before {
  content: "\ed88";
}
.vaylens-icon-trend-down-filled:before {
  content: "\ed89";
}
.vaylens-icon-trend-down-outlined:before {
  content: "\ed8a";
}
.vaylens-icon-trend-up-filled:before {
  content: "\ed8b";
}
.vaylens-icon-trend-up-outlined:before {
  content: "\ed8c";
}
.vaylens-icon-triangle-filled:before {
  content: "\ed8d";
}
.vaylens-icon-triangle-outlined:before {
  content: "\ed8e";
}
.vaylens-icon-tune-horizontal-filled:before {
  content: "\ed8f";
}
.vaylens-icon-tune-horizontal-outlined:before {
  content: "\ed90";
}
.vaylens-icon-tune-vertical-filled:before {
  content: "\ed91";
}
.vaylens-icon-tune-vertical-outlined:before {
  content: "\ed92";
}
.vaylens-icon-undo-filled:before {
  content: "\ed93";
}
.vaylens-icon-undo-outlined:before {
  content: "\ed94";
}
.vaylens-icon-undo-sq-filled:before {
  content: "\ed95";
}
.vaylens-icon-undo-sq-outlined:before {
  content: "\ed96";
}
.vaylens-icon-upload-2-filled:before {
  content: "\ed97";
}
.vaylens-icon-upload-2-outlined:before {
  content: "\ed98";
}
.vaylens-icon-upload-3-filled:before {
  content: "\ed99";
}
.vaylens-icon-upload-3-outlined:before {
  content: "\ed9a";
}
.vaylens-icon-upload-sq-filled:before {
  content: "\ed9b";
}
.vaylens-icon-upload-sq-outlined:before {
  content: "\ed9c";
}
.vaylens-icon-user-add-filled:before {
  content: "\ed9d";
}
.vaylens-icon-user-add-outlined:before {
  content: "\ed9e";
}
.vaylens-icon-user-check-filled:before {
  content: "\ed9f";
}
.vaylens-icon-user-check-outlined:before {
  content: "\eda0";
}
.vaylens-icon-user-circle-filled:before {
  content: "\eda1";
}
.vaylens-icon-user-circle-outlined:before {
  content: "\eda2";
}
.vaylens-icon-user-edit-filled:before {
  content: "\eda3";
}
.vaylens-icon-user-edit-outlined:before {
  content: "\eda4";
}
.vaylens-icon-user-filled:before {
  content: "\eda5";
}
.vaylens-icon-user-hexagon-filled:before {
  content: "\eda6";
}
.vaylens-icon-user-hexagon-outlined:before {
  content: "\eda7";
}
.vaylens-icon-user-outlined:before {
  content: "\eda8";
}
.vaylens-icon-user-remove-filled:before {
  content: "\eda9";
}
.vaylens-icon-user-remove-outlined:before {
  content: "\edaa";
}
.vaylens-icon-user-sq-filled:before {
  content: "\edab";
}
.vaylens-icon-user-sq-outlined:before {
  content: "\edac";
}
.vaylens-icon-user-tag-filled:before {
  content: "\edad";
}
.vaylens-icon-user-tag-outlined:before {
  content: "\edae";
}
.vaylens-icon-vaylens-flash:before {
  content: "\edaf";
}
.vaylens-icon-vaylens-ic-chgarge-port:before {
  content: "\edb0";
}
.vaylens-icon-verify-filled:before {
  content: "\edb1";
}
.vaylens-icon-verify-outlined:before {
  content: "\edb2";
}
.vaylens-icon-video-circle-filled:before {
  content: "\edb3";
}
.vaylens-icon-video-circle-outlined:before {
  content: "\edb4";
}
.vaylens-icon-video-disabled-filled:before {
  content: "\edb5";
}
.vaylens-icon-video-disabled-outlined:before {
  content: "\edb6";
}
.vaylens-icon-video-filled:before {
  content: "\edb7";
}
.vaylens-icon-video-horizontal-filled:before {
  content: "\edb8";
}
.vaylens-icon-video-horizontal-outlined:before {
  content: "\edb9";
}
.vaylens-icon-video-outlined:before {
  content: "\edba";
}
.vaylens-icon-video-play-filled:before {
  content: "\edbb";
}
.vaylens-icon-video-play-outlined:before {
  content: "\edbc";
}
.vaylens-icon-video-sq-filled:before {
  content: "\edbd";
}
.vaylens-icon-video-sq-outlined:before {
  content: "\edbe";
}
.vaylens-icon-video-vertical-filled:before {
  content: "\edbf";
}
.vaylens-icon-video-vertical-outlined:before {
  content: "\edc0";
}
.vaylens-icon-voice-circle-filled:before {
  content: "\edc1";
}
.vaylens-icon-voice-circle-outlined:before {
  content: "\edc2";
}
.vaylens-icon-volume-down-filled:before {
  content: "\edc3";
}
.vaylens-icon-volume-down-outlined:before {
  content: "\edc4";
}
.vaylens-icon-volume-high-filled:before {
  content: "\edc5";
}
.vaylens-icon-volume-high-outlined:before {
  content: "\edc6";
}
.vaylens-icon-volume-low-filled:before {
  content: "\edc7";
}
.vaylens-icon-volume-low-outlined:before {
  content: "\edc8";
}
.vaylens-icon-volume-mute-filled:before {
  content: "\edc9";
}
.vaylens-icon-volume-mute-outlined:before {
  content: "\edca";
}
.vaylens-icon-volume-off-filled:before {
  content: "\edcb";
}
.vaylens-icon-volume-off-outlined:before {
  content: "\edcc";
}
.vaylens-icon-volume-up-filled:before {
  content: "\edcd";
}
.vaylens-icon-volume-up-outlined:before {
  content: "\edce";
}
.vaylens-icon-wallet-1-add-filled:before {
  content: "\edcf";
}
.vaylens-icon-wallet-1-add-outlined:before {
  content: "\edd0";
}
.vaylens-icon-wallet-1-check-filled:before {
  content: "\edd1";
}
.vaylens-icon-wallet-1-check-outlined:before {
  content: "\edd2";
}
.vaylens-icon-wallet-1-closed-filled:before {
  content: "\edd3";
}
.vaylens-icon-wallet-1-closed-outlined:before {
  content: "\edd4";
}
.vaylens-icon-wallet-1-filled:before {
  content: "\edd5";
}
.vaylens-icon-wallet-1-outlined:before {
  content: "\edd6";
}
.vaylens-icon-wallet-1-processing-filled:before {
  content: "\edd7";
}
.vaylens-icon-wallet-1-processing-outlined:before {
  content: "\edd8";
}
.vaylens-icon-wallet-1-remove-filled:before {
  content: "\edd9";
}
.vaylens-icon-wallet-1-remove-outlined:before {
  content: "\edda";
}
.vaylens-icon-wallet-1-search-filled:before {
  content: "\eddb";
}
.vaylens-icon-wallet-1-search-outlined:before {
  content: "\eddc";
}
.vaylens-icon-wallet-1-transfer-filled:before {
  content: "\eddd";
}
.vaylens-icon-wallet-1-transfer-outlined:before {
  content: "\edde";
}
.vaylens-icon-wallet-2-add-filled:before {
  content: "\eddf";
}
.vaylens-icon-wallet-2-add-outlined:before {
  content: "\ede0";
}
.vaylens-icon-wallet-2-check-filled:before {
  content: "\ede1";
}
.vaylens-icon-wallet-2-check-outlined:before {
  content: "\ede2";
}
.vaylens-icon-wallet-2-filled:before {
  content: "\ede3";
}
.vaylens-icon-wallet-2-outlined:before {
  content: "\ede4";
}
.vaylens-icon-wallet-2-processing-filled:before {
  content: "\ede5";
}
.vaylens-icon-wallet-2-processing-outlined:before {
  content: "\ede6";
}
.vaylens-icon-wallet-2-remove-filled:before {
  content: "\ede7";
}
.vaylens-icon-wallet-2-remove-outlined:before {
  content: "\ede8";
}
.vaylens-icon-wallet-2-search-filled:before {
  content: "\ede9";
}
.vaylens-icon-wallet-2-search-outlined:before {
  content: "\edea";
}
.vaylens-icon-wallet-2-transfer-filled:before {
  content: "\edeb";
}
.vaylens-icon-wallet-2-transfer-outlined:before {
  content: "\edec";
}
.vaylens-icon-wallet-3-add-filled:before {
  content: "\eded";
}
.vaylens-icon-wallet-3-add-outlined:before {
  content: "\edee";
}
.vaylens-icon-wallet-3-filled:before {
  content: "\edef";
}
.vaylens-icon-wallet-3-outlined:before {
  content: "\edf0";
}
.vaylens-icon-wallet-3-remove-filled:before {
  content: "\edf1";
}
.vaylens-icon-wallet-3-remove-outlined:before {
  content: "\edf2";
}
.vaylens-icon-warning-1-filled:before {
  content: "\edf3";
}
.vaylens-icon-warning-1-outlined:before {
  content: "\edf4";
}
.vaylens-icon-warning-2-filled:before {
  content: "\edf5";
}
.vaylens-icon-warning-2-outlined:before {
  content: "\edf6";
}
.vaylens-icon-watch-filled:before {
  content: "\edf7";
}
.vaylens-icon-watch-outlined:before {
  content: "\edf8";
}
.vaylens-icon-weight-filled:before {
  content: "\edf9";
}
.vaylens-icon-weight-outlined:before {
  content: "\edfa";
}
.vaylens-icon-wifi-filled:before {
  content: "\edfb";
}
.vaylens-icon-wifi-outlined:before {
  content: "\edfc";
}
.vaylens-icon-wifi-sq-filled:before {
  content: "\edfd";
}
.vaylens-icon-wifi-sq-outlined:before {
  content: "\edfe";
}
.vaylens-icon-wind-filled:before {
  content: "\edff";
}
.vaylens-icon-wind-outlined:before {
  content: "\ee00";
}
.vaylens-icon-workout-filled:before {
  content: "\ee01";
}
.vaylens-icon-workout-outlined:before {
  content: "\ee02";
}
.vaylens-icon-zoom-in-1-filled:before {
  content: "\ee03";
}
.vaylens-icon-zoom-in-1-outlined:before {
  content: "\ee04";
}
.vaylens-icon-zoom-in-2-filled:before {
  content: "\ee05";
}
.vaylens-icon-zoom-in-2-outlined:before {
  content: "\ee06";
}
.vaylens-icon-zoom-out-1-filled:before {
  content: "\ee07";
}
.vaylens-icon-zoom-out-1-outlined:before {
  content: "\ee08";
}
.vaylens-icon-zoom-out-2-filled:before {
  content: "\ee09";
}
.vaylens-icon-zoom-out-2-outlined:before {
  content: "\ee0a";
}
