/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'normalize';
@import 'fonts';
@import 'common';

@import 'base-styles';

@import 'vaylens/typography';

@import '../src/assets/fonts/vaylens_icons/vaylens_icons';
@import '../src/assets/fonts/icons_regular/icons_regular';
@import '../src/assets/fonts/icons_bold/icons_bold';
@import '../src/assets/fonts/icons_light/icons_light';
@import '../src/assets/fonts/icons';
@import '../src/assets/fonts/ePowerDirect/ePowerDirect';
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/** Import Variables and Mixins */
.success-snackbar {
  div.mdc-snackbar__surface {
    background: $successColor;
    margin-bottom: 30px !important;
    border-radius: $radiusL;
  }
}

.error-snackbar {
  div.mdc-snackbar__surface {
    background: $errorColor;
    margin-bottom: 30px !important;
    border-radius: $radiusL;
  }
}

.warning-snackbar {
  div.mdc-snackbar__surface {
    background: $warningColor;
    margin-bottom: 30px !important;
    border-radius: $radiusL;
  }
}

.info-snackbar {
  div.mdc-snackbar__surface {
    background: $infoColor;
    margin-bottom: 30px !important;
    border-radius: $radiusL;
  }
}

.download-progress-snackbar {
  margin: 0 !important;
  position: absolute;
  right: 25px;
  bottom: 0px;
  div.mdc-snackbar__surface {
    background: white;
    margin-bottom: 30px !important;
    border-radius: $radiusXL;
    color: $baseBlack;
  }
}

::ng-deep .mat-mdc-button-touch-target {
  span {
    font-family: $fontBase !important;
  }
}

.ag-theme-alpine {
  --ag-checkbox-checked-color: #{$baseAccent};
  --ag-range-selection-highlight-color: #{$secondaryColor};
  --ag-alpine-active-color: #{$baseAccentText};
  --ag-font-family: #{$fontBase};
  --ag-border-radius: #{$radiusM};
  --ag-row-border-color: #{$borderColor};
  --ag-border-color: #{$borderColor};
  --ag-row-hover-color: #{$baseAccentSelectedHover};
  --ag-secondary-border-color: #{$borderColor};
  --ag-header-background-color: #{$backgroundPrimaryColor};
  --ag-control-panel-background-color: #{$white};
  --ag-side-button-selected-background-color: #{$baseAccentSelectedHover};
  --ag-selected-tab-underline-color: #{$baseAccentSelectedHover};
  --ag-header-foreground-color: #{$baseBlackSecondaryGrey};
  --ag-foreground-color: #{$baseBlackSecondaryGrey};
  --ag-secondary-foreground-color: #{$baseBlackSecondaryGrey};
  --ag-icon-font-color-filter: #{$baseBlackSecondaryGrey};
  --ag-icon-font-color-columns: #{$baseBlackSecondaryGrey};
}
